import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=T55Kb8rrH1g&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=1',
            'https://www.youtube.com/watch?v=OFbSqd54Wwk&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=2',
            'https://www.youtube.com/watch?v=I3y1XHwBL6w&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=3',
            'https://www.youtube.com/watch?v=3CkgSQWwNlk&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=4',
            'https://www.youtube.com/watch?v=5NWdfv5P5d4&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=5',
            'https://www.youtube.com/watch?v=mRTG7fdzF6s&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=6',
            'https://www.youtube.com/watch?v=HXLDBqs5urw&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=7',
            'https://www.youtube.com/watch?v=Mt_6jGHfQyk&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=8',
            'https://www.youtube.com/watch?v=ZZQcDrRl98w&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=9',
            'https://www.youtube.com/watch?v=4TFA-vy_fdo&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=10',
            'https://www.youtube.com/watch?v=ZMEVI1Y7FtY&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=11',
            'https://www.youtube.com/watch?v=DteKTbG2hE0&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=12',
            'https://www.youtube.com/watch?v=PbDXCh4sMIo&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=13',
            'https://www.youtube.com/watch?v=19RpMEonSu8&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=14',
            'https://www.youtube.com/watch?v=brf7roEutrc&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=15',
            'https://www.youtube.com/watch?v=g1uL2byQTzo&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=16',
            'https://www.youtube.com/watch?v=8CAjzElJUiU&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=17',
            'https://www.youtube.com/watch?v=VR9ua1zVqz0&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=18',
            'https://www.youtube.com/watch?v=Ebp7dWMGHlI&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=19',
            'https://www.youtube.com/watch?v=n1a2cITIVeQ&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=20',
            'https://www.youtube.com/watch?v=SgcDucMCngw&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=21',
            'https://www.youtube.com/watch?v=yinZI1Es3kk&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=22',
            'https://www.youtube.com/watch?v=p0dCi5D6gDI&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=23',
            'https://www.youtube.com/watch?v=sEZ1lul6GpI&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=24',
            'https://www.youtube.com/watch?v=Bl3u1VTYTHY&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=25',
            'https://www.youtube.com/watch?v=Lu7q-Uqy7-g&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=26',
            'https://www.youtube.com/watch?v=CP7xxjuAJ0w&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=27',
            'https://www.youtube.com/watch?v=5lWWOJBWeTA&list=PLbtI3_MArDOkXRLxdMt1NOMtCS-84ibHH&index=28',
        ]
    },
    
};

const VideoPageBKD2 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageBKD2;