import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=hw33qwrz7qo&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM',
            'https://www.youtube.com/watch?v=PAicLjIzk8E&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=2',
            'https://www.youtube.com/watch?v=oGL7vHLuPCs&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=3',
            'https://www.youtube.com/watch?v=HGLjUFB7Pqw&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=4',
            'https://www.youtube.com/watch?v=5bQIlPz3avk&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=5',
            'https://www.youtube.com/watch?v=s1n9VpJZWLA&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=6',
            'https://www.youtube.com/watch?v=ntmSsQGr1u4&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=7',
            'https://www.youtube.com/watch?v=F3zbY33rHnQ&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=8',
            'https://www.youtube.com/watch?v=6Ojk6xQRzwU&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=9',
            'https://www.youtube.com/watch?v=3WZXzjGT1nk&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=10',
            'https://www.youtube.com/watch?v=ET9nIxnP9ow&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=11',
            'https://www.youtube.com/watch?v=la6WHfFL5j4&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=12',
            'https://www.youtube.com/watch?v=JyTbtn1vP68&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=13',
            'https://www.youtube.com/watch?v=p3NDyA-wSXo&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=14',
            'https://www.youtube.com/watch?v=rUgom-F1XZM&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=15',
            'https://www.youtube.com/watch?v=vvaULW6pj3U&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=16',
            'https://youtube.com/watch?v=ItNMbk97x28&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=17',
            'https://www.youtube.com/watch?v=Ze1p3cwTPpc&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=18',
            'https://www.youtube.com/watch?v=_TAdVVfoqMo&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=19',
            'https://www.youtube.com/watch?v=-xCzfFY21fw&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=20',
            'https://www.youtube.com/watch?v=GSzkA6ZiXsM&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=21',
            'https://www.youtube.com/watch?v=5Jmckwyyieo&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=22',
            'https://www.youtube.com/watch?v=GaDhqq0A_cY&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=23',
            'https://www.youtube.com/watch?v=HVEV_32HoNU&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=24',
            'https://www.youtube.com/watch?v=k9NoHCY4of4&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=25',
            'https://www.youtube.com/watch?v=_VsyT_wFq9s&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=26',
            'https://www.youtube.com/watch?v=O5Q5T072pvc&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=27',
            'https://www.youtube.com/watch?v=INxAUDSk3pE&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=28',
            'https://www.youtube.com/watch?v=l7O6-rvzM-Y&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=29',
            'https://www.youtube.com/watch?v=OUgH3gVPBO4&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=30',
            'https://www.youtube.com/watch?v=46Vd0mwvt0s&list=PLMkkZSS5OjPIwDyHHKVex6zr008U1-sWM&index=31'

        ]
    },

};

const VideoPageIOS4 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageIOS4;