import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=fmPmrJGbb6w&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB',
            'https://www.youtube.com/watch?v=l7-iZgEfnKg&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=2',
            'https://www.youtube.com/watch?v=HAPQ3oUGgnE&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=3',
            'https://www.youtube.com/watch?v=dsyucuytW2k&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=4',
            'https://www.youtube.com/watch?v=ycHX8QtV08c&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=5',
            'https://www.youtube.com/watch?v=K7gFQ-DbEtY&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=6',
            'https://www.youtube.com/watch?v=CErweok4da8&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=7',
            'https://www.youtube.com/watch?v=2NmtyLUHAfM&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=8',
            'https://www.youtube.com/watch?v=lWmc9Kn9p_I&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=9',
            'https://www.youtube.com/watch?v=fDRtpjHfOuw&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=10',
            'https://www.youtube.com/watch?v=riHDBEfTT2I&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=11',
            'https://www.youtube.com/watch?v=KvozLHxcxuE&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=12',
            'https://www.youtube.com/watch?v=h2U7S-3_rPc&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=13',
            'https://www.youtube.com/watch?v=Lf9DwdVbBuM&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=14',
            'https://www.youtube.com/watch?v=W-0eeS97xV4&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=15',
            'https://www.youtube.com/watch?v=ElZSoFCYmtw&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=16',
            'https://www.youtube.com/watch?v=YLKpz2HfzWo&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=17',
            'https://www.youtube.com/watch?v=BlUd-BAu0DM&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=18',
            'https://www.youtube.com/watch?v=k-b6wvfjm1Y&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=19',
            'https://www.youtube.com/watch?v=DvvnhRo0kHQ&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=20',
            'https://www.youtube.com/watch?v=bwADRDTs5Ho&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=21',
            'https://www.youtube.com/watch?v=97nQgV1tgeY&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=22',
            'https://www.youtube.com/watch?v=lg1JDrPi2Lc&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=23',
            'https://www.youtube.com/watch?v=xiEYNzU4bDg&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=24',
            'https://www.youtube.com/watch?v=1BwjNEKD8g8&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=25',
            'https://www.youtube.com/watch?v=wVSBUnNUr00&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=26',
            'https://youtube.com/watch?v=iY2RYQKPm84&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=27',
            'https://youtube.com/watch?v=g9Uk1Xou0m4&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=28',
            'https://www.youtube.com/watch?v=LJH8UI8E1Lw&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=29',
            'https://www.youtube.com/watch?v=t39VV2XyqR0&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=30',
            'https://www.youtube.com/watch?v=zkxBSCig3zs&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=31',
            'https://www.youtube.com/watch?v=xke5_yGL0uk&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=32',
            'https://www.youtube.com/watch?v=IqJqEJ_J7HE&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=33',
            'https://www.youtube.com/watch?v=jfAYQxYuF5E&list=PLlxmoA0rQ-Lw6tAs2fGFuXGP13-dWdKsB&index=34'

        ]
    },

};

const VideoPageIOS3 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageIOS3;