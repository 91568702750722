import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=jqxz7QvdWk8&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E',
            'https://www.youtube.com/watch?v=PKDWinlLfAo&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=2',
            'https://www.youtube.com/watch?v=BqHOtlh3Dd4&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=3',
            'https://www.youtube.com/watch?v=VPoqbBXzGtA&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=4',
            'https://www.youtube.com/watch?v=SR-AB3RJWbg&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=5',
            'https://www.youtube.com/watch?v=p91tt2AwUjM&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=6',
            'https://www.youtube.com/watch?v=YOHFxBaPGQU&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=7',
            'https://www.youtube.com/watch?v=B3MoTP3veBk&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=8',
            'https://www.youtube.com/watch?v=_qkywk2VeHU&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=9',
            'https://www.youtube.com/watch?v=JzzBYI2LhLI&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=10',
            'https://www.youtube.com/watch?v=tB1sjfijupU&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=11',
            'https://www.youtube.com/watch?v=nsdW6s3FqUY&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=12',
            'https://www.youtube.com/watch?v=fhDvSbcoM0Y&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=13',
            'https://www.youtube.com/watch?v=slopYVjSnMQ&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=14',
            'https://www.youtube.com/watch?v=xxz-v58i7g4&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=15',
            'https://www.youtube.com/watch?v=CWoIIr8KL_s&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=16',
            'https://www.youtube.com/watch?v=vh6Rlka8sOM&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=17',
            'https://www.youtube.com/watch?v=fnpD5NCzIIo&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=18',
            'https://www.youtube.com/watch?v=jEylGBflNCs&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=19',
            'https://www.youtube.com/watch?v=iffK7bIFkG8&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=20',
            'https://www.youtube.com/watch?v=ndchTCcp_Tc&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=21',
            'https://www.youtube.com/watch?v=jPYxYxdZf1M&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=22',
            'https://youtube.com/watch?v=Afpq1r5G4sc&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=23',
            'https://www.youtube.com/watch?v=eqFGKEoY1zA&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=24',
            'https://www.youtube.com/watch?v=4e5tqRXGYQc&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=25',
            'https://www.youtube.com/watch?v=gnfyM0xUNNA&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=26',
            'https://www.youtube.com/watch?v=K8jQrljrL3M&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=27',
            'https://www.youtube.com/watch?v=nUGXH5p7-P0&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=28',
            'https://www.youtube.com/watch?v=_R4Pr3tR0uY&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=29',
            'https://www.youtube.com/watch?v=ATMuWZCu1kA&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=30',
            'https://youtube.com/watch?v=TEbSg0-L2ok&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=31',
            'https://www.youtube.com/watch?v=63IReaxPxhs&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=32',
            'https://youtube.com/watch?v=GjuFaVk4mSs&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=33',
            'https://www.youtube.com/watch?v=YhOSXAAPcsA&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=34',
            'https://www.youtube.com/watch?v=OwApCJUe-8o&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=35',
            'https://www.youtube.com/watch?v=T5M8qAJDHQU&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=36',
            'https://www.youtube.com/watch?v=z323YS-UDr4&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=37',
            'https://www.youtube.com/watch?v=b_YcmSnx8eY&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=38',
            'https://www.youtube.com/watch?v=G16nLcpOLz0&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=39',
            'https://www.youtube.com/watch?v=5hluA7fJzkk&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=40',
            'https://www.youtube.com/watch?v=etySPsyYKsc&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=41',
            'https://www.youtube.com/watch?v=B61Cp1HcchI&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=42',
            'https://www.youtube.com/watch?v=xuyrIVEFNf0&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=43',
            'https://www.youtube.com/watch?v=8-hXkJRNq9s&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=44',
            'https://www.youtube.com/watch?v=4Hm80CjOEWU&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=45',
            'https://www.youtube.com/watch?v=e9YBnPNKEAY&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=46',
            'https://www.youtube.com/watch?v=2lO0EpHP5Zg&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=47',
            'https://www.youtube.com/watch?v=BpN5f_747pI&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=48',
            'https://www.youtube.com/watch?v=Q80ge-0mUGs&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=49',
            'https://www.youtube.com/watch?v=PtNOp4MQ32g&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=50',
            'https://youtube.com/watch?v=UIpTwzJ-TTU&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=51',
            'https://www.youtube.com/watch?v=UsFq21gYn5Q&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=52',
            'https://www.youtube.com/watch?v=wa6ZtSRfI0w&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=53',
            'https://www.youtube.com/watch?v=Fdm9USwhiWk&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=54',
            'https://www.youtube.com/watch?v=jJGJCb0lVIc&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=55',
            'https://www.youtube.com/watch?v=HuD3Yx_QdCY&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=56',
            'https://www.youtube.com/watch?v=cIQ44oBlUA4&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=57',
            'https://youtube.com/watch?v=-AHYS_fW-ow&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=58',
            'https://www.youtube.com/watch?v=J248xKjnmck&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=59',
            'https://youtube.com/watch?v=W3BeEx2fiHw&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=60',
            'https://www.youtube.com/watch?v=GfFzcnG0sRs&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=61',
            'https://www.youtube.com/watch?v=oWGWeH3Rle8&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=62',
            'https://youtube.com/watch?v=h46lrbg8qUg&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=63',
            'https://www.youtube.com/watch?v=4TnI5JarxGs&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=64',
            'https://www.youtube.com/watch?v=0LoqMKE5Kz8&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=65',
            'https://www.youtube.com/watch?v=1YxHlJyjQWM&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=66',
            'https://www.youtube.com/watch?v=GCRmDHhTodE&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=67',
            'https://www.youtube.com/watch?v=CqYF5-tuHvY&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=68',
            'https://www.youtube.com/watch?v=Wk0fau-CPas&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=69',
            'https://www.youtube.com/watch?v=OwFMEhZbe_4&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=70',
            'https://www.youtube.com/watch?v=TAjgdAsHCaU&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=71',
            'https://www.youtube.com/watch?v=1xdC7a6Lmww&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=72',
            'https://www.youtube.com/watch?v=mEUONqQIzC0&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=73',
            'https://www.youtube.com/watch?v=ZbVoHGpVEPE&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=74',
            'https://www.youtube.com/watch?v=4_NDWUGDZRQ&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=75',
            'https://www.youtube.com/watch?v=v1PdkGW1CmA&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=76',
            'https://www.youtube.com/watch?v=DukXpmkEHb4&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=77',
            'https://www.youtube.com/watch?v=GDB_zYFd0MA&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=78',
            'https://www.youtube.com/watch?v=esp-vyw040w&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=79',
            'https://youtube.com/watch?v=VISoK50eKvM&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=80',
            'https://www.youtube.com/watch?v=hiZcVbyukBo&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=81',
            'https://www.youtube.com/watch?v=jljwoxLOuSA&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=82',
            'https://www.youtube.com/watch?v=glr_5OOH6HQ&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=83',
            'https://youtube.com/watch?v=MWmtbZQKCGY&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=84',
            'https://www.youtube.com/watch?v=EFSFfEtznmU&list=PLjVLYmrlmjGfGLShoW0vVX_tcyT8u1Y3E&index=85'

        ]
    },

};

const VideoPageIOS1 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageIOS1;