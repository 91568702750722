import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=ohIAiuHMKMI&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=1',
            'https://www.youtube.com/watch?v=N6PjgN9licA&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=2',
            'https://www.youtube.com/watch?v=XhCs5cTYW_8&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=3',
            'https://www.youtube.com/watch?v=FSRo41TaHFU&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=4',
            'https://www.youtube.com/watch?v=YazJFb_i4A0&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=5',
            'https://www.youtube.com/watch?v=y0aTs56DJWk&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=6',
            'https://www.youtube.com/watch?v=ZQsrcayZcSk&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=7',
            'https://www.youtube.com/watch?v=Nt-AsZh5woE&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=8',
            'https://www.youtube.com/watch?v=kREAjKyPbSI&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=9',
            'https://www.youtube.com/watch?v=N2-FyBBxOZA&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=10',
            'https://www.youtube.com/watch?v=ORmB_ABimjM&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=11',
            'https://www.youtube.com/watch?v=cJAyEOZQUQY&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=12',
            'https://www.youtube.com/watch?v=uNCrMvkPUAE&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=13',
            'https://www.youtube.com/watch?v=7OzNVIxPLH0&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=14',
            'https://www.youtube.com/watch?v=n2c0mf1sza4&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=15',
            'https://www.youtube.com/watch?v=mhg3Vwsb88M&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=16',
            'https://www.youtube.com/watch?v=fLGw2GK884s&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=17',
            'https://www.youtube.com/watch?v=oH0VoYiA8_s&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=18',
            'https://www.youtube.com/watch?v=xrglM8U0Zv8&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=19',
            'https://www.youtube.com/watch?v=JLtXoru-ipo&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=20',
            'https://www.youtube.com/watch?v=4WvX9dBjiJo&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=21',
            'https://www.youtube.com/watch?v=yy9cbu_e3Xg&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=22',
            'https://www.youtube.com/watch?v=OWeruyqhiTo&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=23',
            'https://www.youtube.com/watch?v=mGrVmEex6_g&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=24',
            'https://www.youtube.com/watch?v=kMErso06vHo&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=25',
            'https://www.youtube.com/watch?v=1uxWtBFOrkk&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=26',
            'https://www.youtube.com/watch?v=5UOMuTWrh6Q&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=27',
            'https://www.youtube.com/watch?v=WqJ0P8JnftI&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=28',
            'https://www.youtube.com/watch?v=6z6CR29gtds&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=29',
            'https://www.youtube.com/watch?v=X1AGH8Byj9Y&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=30',
            'https://www.youtube.com/watch?v=vnWVrjsEeAM&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=31',
            'https://www.youtube.com/watch?v=_U0pTlpyMGg&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=32',
            'https://www.youtube.com/watch?v=UUddpbgPEJM&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=33',
            'https://www.youtube.com/watch?v=64LJJhT6Ybo&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=34',
            'https://www.youtube.com/watch?v=JoPZ9gEvpz8&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=35',
            'https://www.youtube.com/watch?v=b_B1BEShfBc&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=36',
            'https://www.youtube.com/watch?v=Qmld1te08Ns&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=37',
            'https://www.youtube.com/watch?v=YkuhqIDUi2A&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=38',
            'https://www.youtube.com/watch?v=ofBFl4M4BFk&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=39',
            'https://www.youtube.com/watch?v=VvYADzRwJK8&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=40',
            'https://www.youtube.com/watch?v=AgOmeANl3ls&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=41',
            'https://www.youtube.com/watch?v=WtkKwO1viI8&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=42',
            'https://www.youtube.com/watch?v=FbDrEEhG5cM&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=43',
            'https://www.youtube.com/watch?v=g6YpoVYTd1s&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=44',
            'https://www.youtube.com/watch?v=4jiTZGn34_c&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=45',
            'https://www.youtube.com/watch?v=MSJImC6cTkY&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=46',
            'https://www.youtube.com/watch?v=0XFoeaYnc20&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=47',
            'https://www.youtube.com/watch?v=8atiBUsAOcI&list=PLinedj3B30sDby4Al-i13hQJGQoRQDfPo&index=48',
        ]
    },
    
};

const VideoPageBKD3 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageBKD3;