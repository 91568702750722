import React from 'react';
import girlImage from "../../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=_MFBAtFLNKc&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F',
            'https://www.youtube.com/watch?v=NTmh8l-Xl4c&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=2',
            'https://www.youtube.com/watch?v=3z0rrh6WOQM&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=3',
            'https://www.youtube.com/watch?v=6oaOXxjKITE&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=4',
            'https://www.youtube.com/watch?v=pnPBGT4Xhts&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=5',
            'https://www.youtube.com/watch?v=42uQGucQA9o&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=6',
            'https://www.youtube.com/watch?v=nExMC7NYro8&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=7',
            'https://www.youtube.com/watch?v=kZiuK29eIgg&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=8',
            'https://www.youtube.com/watch?v=KE6BaMFI_ak&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=9',
            'https://www.youtube.com/watch?v=c0ZTaDMGb20&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=10',
            'https://www.youtube.com/watch?v=Y9n8hFA9uZA&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=11',
            'https://www.youtube.com/watch?v=gwiX0oASlEw&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=12',
            'https://www.youtube.com/watch?v=TFWoG0062jU&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=13',
            'https://www.youtube.com/watch?v=SYtxek-gZtY&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=14',
            'https://www.youtube.com/watch?v=aMNsQDzZipM&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=15',
            'https://www.youtube.com/watch?v=GixCMumsL40&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=16',
            'https://www.youtube.com/watch?v=zL_XPR37zHY&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=17',
            'https://www.youtube.com/watch?v=omf3uoFd1c8&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=18',
            'https://www.youtube.com/watch?v=sXEU3gQkVtE&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=19',
            'https://www.youtube.com/watch?v=FD0Ql0XZ7ZA&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=20',
            'https://www.youtube.com/watch?v=ZtN3yHIWuRY&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=21',
            'https://www.youtube.com/watch?v=gHdcAH1nhiU&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=22',
            'https://www.youtube.com/watch?v=hYBd0tTs70Q&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=23',
            'https://www.youtube.com/watch?v=aKscPJWVfqs&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=24',
            'https://www.youtube.com/watch?v=rkA4kHeXVz4&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=25',
            'https://www.youtube.com/watch?v=NH0AYyLoRuE&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=26',
            'https://www.youtube.com/watch?v=bQ9DS4G9FB8&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=27',
            'https://www.youtube.com/watch?v=mH6njJlPiLg&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=28',
            'https://www.youtube.com/watch?v=-LMb1Bo7oHw&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=29',
            'https://www.youtube.com/watch?v=uC1VK4ktsFc&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=30',
            'https://www.youtube.com/watch?v=YqXYei3eLxs&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=31',
            'https://www.youtube.com/watch?v=CesC-FaUT38&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=32',
            'https://www.youtube.com/watch?v=4szMtZNKELU&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=33',
            'https://www.youtube.com/watch?v=S9g_7dUJLzM&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=34',
            'https://www.youtube.com/watch?v=8h-QEg6r4tM&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=35',
            'https://www.youtube.com/watch?v=sAtK8RDH3vk&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=36',
            'https://www.youtube.com/watch?v=MX-2xs93bMA&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=37',
            'https://www.youtube.com/watch?v=hWaijk6d7js&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=38',
            'https://www.youtube.com/watch?v=HfcMYRIC0Fw&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=39',
            'https://www.youtube.com/watch?v=wUXa6ZPG6Lw&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=40',
            'https://www.youtube.com/watch?v=NTyNvaKOcpE&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=41',
            'https://www.youtube.com/watch?v=M9oWnKkgBi4&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=42',
            'https://www.youtube.com/watch?v=XKudQA6gsTY&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=43',
            'https://www.youtube.com/watch?v=X2WbCdIKgDk&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=44',
            'https://www.youtube.com/watch?v=jrQNtNm2-Qs&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=45',
            'https://www.youtube.com/watch?v=fn4rP20U2UM&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=46',
            'https://www.youtube.com/watch?v=13gTtpcvCp8&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=47',
            'https://www.youtube.com/watch?v=mtmYqqbpUVs&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=48',
            'https://www.youtube.com/watch?v=grKp_TVAaTE&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=49',
            'https://www.youtube.com/watch?v=HH0ancOHSdc&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=50',
            'https://www.youtube.com/watch?v=NzXamkyOsv4&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=51',
            'https://www.youtube.com/watch?v=tL0Jrwo2V_U&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=52',
            'https://www.youtube.com/watch?v=EobSNkzdN7A&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=53',
            'https://www.youtube.com/watch?v=-wxqo771bTY&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=54',
            'https://www.youtube.com/watch?v=2bG3VEtWM6E&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=55',
            'https://www.youtube.com/watch?v=YqkyasAg00I&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=56',
            'https://www.youtube.com/watch?v=-McAJ9Bmsxw&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=57',
            'https://www.youtube.com/watch?v=TvmEdMKddtI&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=58',
            'https://www.youtube.com/watch?v=nOg7dv3wBrs&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=59',
            'https://www.youtube.com/watch?v=bSJb3qrJpL8&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=60',
            'https://www.youtube.com/watch?v=z7ZKU6dH2tU&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=61',
            'https://www.youtube.com/watch?v=pIF_zIDaZ94&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=62',
            'https://www.youtube.com/watch?v=O6Jb7mYwpZ4&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=63',
            'https://www.youtube.com/watch?v=yzshSsZcwtE&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=64',
            'https://www.youtube.com/watch?v=SxL7W69-ces&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=65',
            'https://www.youtube.com/watch?v=P-BniQJcymE&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=66',
            'https://www.youtube.com/watch?v=JKOl-LC7Nx8&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=67',
            'https://www.youtube.com/watch?v=IMNA9ASTEV4&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=68',
            'https://www.youtube.com/watch?v=NOKsRvPzWP4&list=PLjiHFwhbHYlHSpAflJwjsKAyMaMhASm0F&index=69',
        ]
    },
    
};

const VideoPageUI3 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageUI3;
