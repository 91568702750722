import React from 'react';
import './AboutUsPage.css';

const AboutUsPage = () => {
    return (
        <>
            <div className="about-us-container">
                {/* Left Section */}
                <div className="left-content">
                    <h1>About Us</h1>
                    <p>
                    Welcome to Aminurmus Technology Learning/Training , your one-stop destination for 
                    mastering the skills required to thrive in today’s fast-paced IT industry. We are 
                    a comprehensive learning platform designed to empower individuals with the knowledge
                     and practical experience needed to excel in the world of technology. Whether you're 
                     a beginner taking your first steps or an experienced professional aiming to upgrade 
                     your skills, we offer courses that cater to all levels of expertise.

                    Our Mission:
                    Our mission is to make top-quality IT education accessible to everyone. We aim to bridge
                     the gap between theoretical learning and real-world application by offering hands-on 
                     courses designed by industry experts. With an ever-evolving curriculum, we ensure that 
                     our learners are equipped with the latest tools, technologies, and techniques to stay 
                     ahead in the competitive job market.

                    What We Offer:
                    Wide Range of Courses: We cover a diverse array of IT disciplines, including Web Development, 
                    Mobile App Development, Data Science, Cloud Computing, Artificial Intelligence, Machine Learning, 
                    Cybersecurity, DevOps, and more.

                    Industry Experts: Our courses are created and delivered by professionals from top companies
                    like Amazon, Google, Facebook, and Adobe. You’ll gain insights directly from those who are 
                    shaping the future of technology.

                    Project-Based Learning: We believe in learning by doing. Our platform integrates real-world
                    projects into every course, allowing you to apply the concepts you’ve learned in a 
                    practical setting.

                    Flexible Learning: Whether you're a student, a working professional, or someone looking 
                    to make a career change, our self-paced learning modules are designed to fit your schedule.

                    Career Support: From resume building to interview preparation, we provide full career 
                    guidance to help you land your dream job. Our learners also get the opportunity to work 
                    on live projects from top-tier companies, providing invaluable experience and exposure.

                    Why Choose Us?
                    Learning from Top Professionals: Gain insights and knowledge directly from top IT 
                    professionals who work at global tech giants and who bring industry-best practices 
                    into every course.

                    Hands-On Projects: Each course is designed with practical projects that mimic real-world 
                    challenges, giving you a portfolio to showcase your skills.

                    Certification & Job Opportunities: Upon course completion, you'll receive certification 
                    recognized across the industry, along with potential job placement and internship 
                    opportunities based on your performance.

                    At Technology Learning/Training , we’re not just about learning—we’re about helping 
                    you build a successful career in IT. Join us today and take the first step towards your 
                    professional transformation!
                    </p>
                    {/* <p>
          Our focus is on delivering high-quality software with a strong emphasis on usability, 
          performance, and security.
        </p> */}
                    <button className="read-more-btn">Read Me</button>
                </div>

                {/* Right Section (Image) */}
                <div className="right-content">
                    <img
                        src="https://img.freepik.com/free-photo/entrepreneurs-meeting-office_23-2148898686.jpg?t=st=1727715185~exp=1727718785~hmac=d697ba905aab165b974a7989d512270826adc6d5c9639456b9e1c50103bab7f0&w=900"
                        alt="About Us"
                        className="about-image"
                    />
                </div>
            </div>
            
        </>
    );
};

export default AboutUsPage;















// import React from 'react';
// import './AboutUsPage.css';

// const AboutUsPage = () => {
//   return (
//     <div className="about-us-container">
//       {/* Left Section */}
//       <div className="left-content">
//         <h1>About Us</h1>
//         <p>
//           We are a team of dedicated developers and designers, passionate about building 
//           innovative web applications and providing cutting-edge solutions to clients.
//           We are a team of dedicated developers and designers, passionate about building 
//           innovative web applications and providing cutting-edge solutions to clients.
//           We are a team of dedicated developers and designers, passionate about building 
//           innovative web applications and providing cutting-edge solutions to clients.
//           We are a team of dedicated developers and designers, passionate about building 
//           innovative web applications and providing cutting-edge solutions to clients.
//         </p>
//         <p>
//           Our focus is on delivering high-quality software with a strong emphasis on usability, 
//           performance, and security.
//         </p>
//         <button className="read-more-btn">Read Me</button>
//       </div>

//       {/* Right Section (Image) */}
//       <div className="right-content">
//         <img
//           src="https://via.placeholder.com/400"
//           alt="About Us"
//           className="about-image"
//         />
//       </div>
//     </div>
//   );
// };

// export default AboutUsPage;
