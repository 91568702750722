import React, { useState } from 'react';
import './ComponentPage.css';

function ComponentPage() {
  const [modalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleClickOutside = (event) => {
    if (event.target.id === 'id01') {
      setModalVisible(false);
    }
  };

  return (
    <>
    <div className='l_body'>
      <p onClick={toggleModal} className="">
        formate password
      </p>

      {modalVisible && (
        <div id="id01" className="l_modal" onClick={handleClickOutside}>
          <form className="l_modal-content l_animate">
          <h1 className='sign_Heading'>Send Password Reset Email</h1>
            {/* <div className="l_close_container">
              <span onClick={toggleModal} className="l_close" title="Close Modal">
                &times;
              </span>
            </div> */}

            <div className="l_container">
              <label htmlFor="uname">
                <b>Email</b>
              </label>
              <input className='l_btn'  type="text" placeholder="Enter Email" name="email" required />

              {/* <label htmlFor="psw">
                <b>Password</b>
              </label>
              <input className='l_btn' type="password" placeholder="Enter Password" name="psw" required /> */}

              <button type="submit">Send Email</button>
            </div>

            {/* <div className="l_container" style={{ backgroundColor: '#f1f1f1' }}>
              <button type="button" onClick={toggleModal} className="l_cancelbtn">
                Cancel
              </button>
            </div> */}
          </form>
        </div>
      )}
    </div>
    </>
  );
}
export default ComponentPage;












//  overlaping page///


// import React, { useState } from 'react';
// import './ComponentPage.css';

// const ComponentPage = () => {
//   const [isLoginActive, setIsLoginActive] = useState(true);

//   const switchForm = () => {
//     setIsLoginActive(!isLoginActive);
//   };

//   return (
//     <section className="forms-section">
//       <h1 className="section-title">Login & Signup Forms</h1>
//       <div className="forms">
//         {/* Login Form */}
//         <div className={`form-wrapper ${isLoginActive ? 'is-active' : ''}`}>
//           <button type="button" className="switcher switcher-login" onClick={switchForm}>
//             Login
//             <span className="underline"></span>
//           </button>
//           <form className="form form-login">
//             <fieldset>
//               <legend>Please, enter your email and password for login.</legend>
//               <div className="input-block">
//                 <label htmlFor="login-email">E-mail</label>
//                 <input id="login-email" type="email" required />
//               </div>
//               <div className="input-block">
//                 <label htmlFor="login-password">Password</label>
//                 <input id="login-password" type="password" required />
//               </div>
//             </fieldset>
//             <button type="submit" className="btn-login">Login</button>
//           </form>
//         </div>

//         {/* Signup Form */}
//         <div className={`form-wrapper ${!isLoginActive ? 'is-active' : ''}`}>
//           <button type="button" className="switcher switcher-signup" onClick={switchForm}>
//             Sign Up
//             <span className="underline"></span>
//           </button>
//           <form className="form form-signup">
//             <fieldset>
//               <legend>Please, enter your email, password, and password confirmation for sign up.</legend>
//               <div className="input-block">
//                 <label htmlFor="signup-username">Username</label>
//                 <input id="signup-username" type="text" required />
//               </div>
//               <div className="input-block">
//                 <label htmlFor="signup-email">E-mail</label>
//                 <input id="signup-email" type="email" required />
//               </div>
//               <div className="input-block">
//                 <label htmlFor="signup-password">Password</label>
//                 <input id="signup-password" type="password" required />
//               </div>
//             </fieldset>
//             <button type="submit" className="btn-signup">Sign Up</button>
//           </form>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ComponentPage;














// import React, { useState } from 'react';
// import './ComponentPage.css';

// const ComponentPage = () => {
//   const [flipped, setFlipped] = useState(false);
//   const [joke, setJoke] = useState('#VetsWhoJoke');

//   const getDadJoke = () => {
//     fetch('https://icanhazdadjoke.com/', {
//       headers: {
//         Accept: 'application/json',
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => setJoke(data.joke));
//   };

//   const revealJoke = () => {
//     setFlipped(!flipped);
//     if (!flipped) {
//       getDadJoke();
//     }
//   };

//   return (
//     <div className='c_body'>
//       <div className="c_container">
//         <div className={`flip_card ${flipped ? 'flipped' : ''}`} onClick={revealJoke}>
//           <div className="c_front">
//             <p id="c_content-dadJoke-front">{'#VetsWhoJoke'}</p>
//           </div>
//           <div className="c_back">
//             <p id="c_content-dadJoke-back">{joke}</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComponentPage;








// ----Flip Card With Animation----

// import React, { useState } from 'react';
// import './ComponentPage.css'; // Import the CSS file

// const ComponentPage = () => {
//   const [flipped, setFlipped] = useState(false);

//   const handleClick = () => {
//     setFlipped(!flipped);
//   };

//   return (
//     <div className="flipCard">
//       <div className={`card ${flipped ? 'flipped' : ''}`} onClick={handleClick}>
//         <div className="side front">Keyword</div>
//         <div className="side back">Definition<br/>Explanation</div>
//       </div>
//     </div>
//   );
// };

// export default ComponentPage;










// // ---   Gift Card With Animation  ---


// import React, { useState } from 'react';
// import './ComponentPage.css'; // Assuming you have the same CSS imported

// const ComponentPage = () => {
//   const [isFlipped, setIsFlipped] = useState(false);
//   const [isSent, setIsSent] = useState(false);
//   const [formData, setFormData] = useState({
//     from: '',
//     to: '',
//     message: ''
//   });

//   const handleFlip = () => {
//     setIsFlipped(!isFlipped);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSend = () => {
//     setIsSent(true);
//     setTimeout(() => {
//       setFormData({ from: '', to: '', message: '' });
//       setIsSent(false);
//       setIsFlipped(false);
//     }, 2400);
//   };

//   return (
//     <div className="wrap">
//       <div className="gift-card-box">
//         {isSent && (
//           <div className="confirmation visible">
//             <svg className="all-good" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
//               <circle className="path circle" fill="none" stroke="#777" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
//               <polyline className="path check" fill="none" stroke="#777" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
//             </svg>
//             <p className="success">Right on! Send another?</p>
//           </div>
//         )}
//         <div className={`card ${isFlipped ? 'flip' : ''} ${isSent ? 'whoosh' : ''}`}>
//           <div className="front">
//             <div className="front-logo-cnt">
//               <svg className="apple-logo" xmlns="http://www.w3.org/2000/svg" width="256" height="315" viewBox="0 0 256 315">
//                 <path fill="#fff" d="... (Apple logo SVG path)" />
//               </svg>
//             </div>
//           </div>
//           <div className="back">
//             <form className="form" autoComplete="off" noValidate>
//               <fieldset>
//                 <label htmlFor="from">From:</label>
//                 <input
//                   type="text"
//                   name="from"
//                   className="input-card-info"
//                   maxLength="30"
//                   value={formData.from}
//                   onChange={handleInputChange}
//                   autoFocus
//                 />
//               </fieldset>
//               <fieldset>
//                 <label htmlFor="to">To:</label>
//                 <input
//                   type="text"
//                   name="to"
//                   className="input-card-info"
//                   maxLength="30"
//                   value={formData.to}
//                   onChange={handleInputChange}
//                 />
//               </fieldset>
//               <fieldset>
//                 <label htmlFor="message">Message:</label>
//                 <input
//                   type="text"
//                   name="message"
//                   className="input-card-info input-card-info--lg"
//                   maxLength="80"
//                   value={formData.message}
//                   onChange={handleInputChange}
//                 />
//               </fieldset>
//             </form>
//           </div>
//         </div>
//         <div className="button-cnt">
//           {!isFlipped ? (
//             <button id="btn-card-flip" className="primary-cta" onClick={handleFlip}>
//               Customise
//             </button>
//           ) : (
//             <>
//               <button id="close" className="secondary-cta secondary-cta--done" onClick={handleFlip}>
//                 Close
//               </button>
//               <button id="btn-card-send" className="secondary-cta secondary-cta--send" onClick={handleSend}>
//                 Send
//               </button>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComponentPage;













// //---    CardFlip With Animation   ---


// import React, { useState } from 'react';
// import './ComponentPage.css';

// const ComponentPage = () => {
//   const [isFlipped, setIsFlipped] = useState(false);

//   const handleFlip = () => {
//     setIsFlipped(!isFlipped);
//   };

//   return (
//     <div className={`container ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
//       <div className="front side">
//         <div className="content">
//           <h1>Hakkam Abdullah</h1>
//           <p>
//             I am a graphic designer and art director. I am as well specialised in front end web design, user experience and creating identities. Throughout my career, I have worked with companies of all shapes and sizes that enriched my experience.
//           </p>
//         </div>
//       </div>
//       <div className="back side">
//         <div className="content">
//           <h1>Contact Me</h1>
//           <form>
//             <label>Your Name:</label>
//             <input type="text" placeholder="Omar Khattab" />
//             <label>Your E-mail:</label>
//             <input type="text" placeholder="Example@mail.com" />
//             <label>Your message:</label>
//             <textarea placeholder="The Subject"></textarea>
//             <input type="submit" value="Done" />
//           </form>
//         </div>
//       </div>
//       <a target="_blank" href="http://codepen.io/Moslim/" className="white-mode">OTHER PENS</a>
//     </div>
//   );
// };

// export default ComponentPage;


