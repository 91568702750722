import React from 'react';
import './FooterPage.css';
function FooterPage() {
  return (
    <>
     {/* Footer Section */}
     <footer className="footer">
                <div className="footer-container">
                    {/* Left Section: Logo and Company Name */}
                    <div className="footer-left">
                        <img src="logo.png" alt="Company Logo" className="footer-logo" />
                        <h2 className="company-name">AMINURMUS TECHNOLOGY</h2>
                        <p className="company-description">
                            AMINURMUS TECHNOLOGY is a leading firm specializing in innovative solutions for modern businesses.
                            We strive to deliver cutting-edge technology that empowers companies to grow, innovate, and lead in their industries.
                            
                        </p>
                    </div>

                    {/* Center Section: About Us & Contact */}
                    <div className="footer-center">
                        {/* <div className="vertical-line"></div> */}
                        <div className="center-links">
                            <h4>About Us</h4>
                            <h5>Contact</h5>
                            <h5>Services</h5>
                            {/* <a href="#about">Our Story</a>
                            <a href="#contact">Contact</a>
                            <a href="#services">Services</a> */}
                        </div>
                    </div>

                    {/* Right Section: Terms & Privacy */}
                    <div className="footer-right">
                        {/* <div className="vertical-line"></div> */}
                        <div className="right-links">
                            <h4>Legal</h4>
                            <a href="#terms">Terms & Conditions</a>
                            <a href="#privacy">Privacy Policy</a>
                            <a href="#faq">FAQ</a>
                        </div>
                    </div>
                </div>
            </footer>
    </>
  )
}

export default FooterPage
