import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=vz1RlUyrc3w&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=1',
            'https://www.youtube.com/watch?v=k3KqQvywToE&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=2',
            'https://www.youtube.com/watch?v=yNbnA5pryMg&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=3',
            'https://www.youtube.com/watch?v=kAOuj6o7Kxs&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=4',
            'https://www.youtube.com/watch?v=lI7IIOWM0Mo&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=5',
            'https://www.youtube.com/watch?v=MPCVGFvgVEQ&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=6',
            'https://www.youtube.com/watch?v=bB6707XzCNc&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=7',
            'https://www.youtube.com/watch?v=tOYkV6Yhrhs&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=8',
            'https://www.youtube.com/watch?v=_lJ3KNMue3w&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=9',
            'https://www.youtube.com/watch?v=Lt4vy8hfc-s&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=10',
            'https://www.youtube.com/watch?v=AFDYnd-XPa8&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=11',
            'https://www.youtube.com/watch?v=VJov5QWEKE4&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=12',
            'https://www.youtube.com/watch?v=JQVBGtZMqgU&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=13',
            'https://www.youtube.com/watch?v=6KQeopPE36I&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=14',
            'https://www.youtube.com/watch?v=1i04-A7kfFI&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=15',
            'https://www.youtube.com/watch?v=CqNSTD9ENb0&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=16',
            'https://www.youtube.com/watch?v=P-WHzz2M5aU&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=17',
            'https://www.youtube.com/watch?v=zLWif1pFYJg&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=18',
            'https://www.youtube.com/watch?v=4_JlIr8yry0&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=19',
            'https://www.youtube.com/watch?v=0Py5cGGW2lE&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=20',
            'https://www.youtube.com/watch?v=lzx52HnWh4Y&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=21',
            'https://www.youtube.com/watch?v=8QGKg_W5sDQ&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=22',
            'https://www.youtube.com/watch?v=BSaYsHVpaK0&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=23',
            'https://www.youtube.com/watch?v=lfMyCuB6xfc&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=24',
            'https://www.youtube.com/watch?v=-6LvNku2nJE&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=25',
            'https://www.youtube.com/watch?v=rC644qOZUro&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=26',
            'https://www.youtube.com/watch?v=Od4rQCU41s4&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=27',
            'https://www.youtube.com/watch?v=dg2Gw1HSlpQ&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=28',
            'https://www.youtube.com/watch?v=KqGze7HCTIA&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=29',
            'https://www.youtube.com/watch?v=3o4qc9WRtWE&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=30',
            'https://www.youtube.com/watch?v=fFHyqhmnVfs&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=31',
            'https://www.youtube.com/watch?v=P4X0vPTQX4A&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=32',
            'https://www.youtube.com/watch?v=M3AxZX3g00w&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=33',
            'https://www.youtube.com/watch?v=NxAwOjb_NlA&list=PLu71SKxNbfoDqgPchmvIsL4hTnJIrtige&index=34',
        ]
    },
};

const VideoPageRD4 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageRD4;