import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=IM5bItHA3vU&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=1',
            'https://www.youtube.com/watch?v=3d-leGuuva4&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=2',
            'https://www.youtube.com/watch?v=7yAJrTMEAWc&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=3',
            'https://www.youtube.com/watch?v=4SgKxPLuWeU&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=4',
            'https://www.youtube.com/watch?v=TsVWTYhCevA&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=5',
            'https://www.youtube.com/watch?v=aPIUohg4mAU&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=6',
            'https://www.youtube.com/watch?v=gJ6cvzZ0ewQ&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=7',
            'https://www.youtube.com/watch?v=xXQbV1NBSf0&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=8',
            'https://www.youtube.com/watch?v=n9PF_N7zRI0&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=9',
            'https://www.youtube.com/watch?v=RkzzUoFzVAM&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=10',
            'https://www.youtube.com/watch?v=wQ4CalfBMPg&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=11',
            'https://www.youtube.com/watch?v=-IJTrPPGmJY&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=12',
            'https://www.youtube.com/watch?v=xWq9WbeyM14&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=13',
            'https://www.youtube.com/watch?v=g2GLxe_rHho&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=14',
            'https://www.youtube.com/watch?v=35xzWiFJxOg&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=15',
            'https://www.youtube.com/watch?v=9PHUqwe3qBk&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=16',
            'https://www.youtube.com/watch?v=FO-aVVrwVW8&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=19',
            'https://www.youtube.com/watch?v=fG6s2KFfWrU&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=20',
            'https://www.youtube.com/watch?v=wpezX180vvM&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=21',
            'https://www.youtube.com/watch?v=rsTdo9NhjSs&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=22',
            'https://www.youtube.com/watch?v=b6ZDsVpC01M&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=23',
            'https://www.youtube.com/watch?v=HWlKqM1hrRk&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=24',
            'https://www.youtube.com/watch?v=gNza6MJb5jo&list=PLoo9_ntqDMDCtaFIYfvdFOyEs4oSk6jUz&index=25',
        ]
    },
    
};

const VideoPageBKD7 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageBKD7;