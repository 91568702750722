import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=oMj95u2zK4Y&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v',
            'https://www.youtube.com/watch?v=OTNylafKCJE&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=2',
            'https://www.youtube.com/watch?v=kkddIQ97j08&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=3',
            'https://www.youtube.com/watch?v=oBuTWzuZkA4&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=4',
            'https://www.youtube.com/watch?v=G6ZX7ivQhzI&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=5',
            'https://www.youtube.com/watch?v=kvJYjvoncak&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=6',
            'https://www.youtube.com/watch?v=MIO4sLhyh1Q&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=7',
            'https://www.youtube.com/watch?v=Rzf9DS6_Ezc&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=8',
            'https://www.youtube.com/watch?v=mXtEhgRpRa4&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=9',
            'https://www.youtube.com/watch?v=yuiZliV4pWk&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=10',
            'https://www.youtube.com/watch?v=z_7g1OHW0Dw&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=11',
            'https://www.youtube.com/watch?v=9FbVRM55wr0&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=12',
            'https://www.youtube.com/watch?v=1FDGE49Z7Y4&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=13',
            'https://www.youtube.com/watch?v=2e2kqED8Bcg&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=14',
            'https://www.youtube.com/watch?v=jdZqEbHVtZg&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=15',
            'https://www.youtube.com/watch?v=Nwhwci0vwmE&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=16',
            'https://www.youtube.com/watch?v=ccLIho-WqG0&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=17',
            'https://www.youtube.com/watch?v=1VitBcLRP_M&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=18',
            'https://www.youtube.com/watch?v=xjq6Wwgkka8&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=19',
            'https://www.youtube.com/watch?v=53ftOZ-Q4N4&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=20',
            'https://www.youtube.com/watch?v=8FwRLYbr5YM&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=21',
            'https://www.youtube.com/watch?v=XR7Q9Bu3pEI&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=22',
            'https://www.youtube.com/watch?v=-xDTNSEC8nM&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=23',
            'https://www.youtube.com/watch?v=-5E-uRrMUKg&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=24',
            'https://www.youtube.com/watch?v=AXq9r0_kfbo&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=25',
            'https://www.youtube.com/watch?v=WWMoTYvrSjY&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=26',
            'https://www.youtube.com/watch?v=4kDeGqXc1ZU&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=27',
            'https://www.youtube.com/watch?v=xbFdFreTi1k&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=28',
            'https://www.youtube.com/watch?v=IiZu-Eg3qRM&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=29',
            'https://www.youtube.com/watch?v=ffCG8Xi3RcU&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=30',
            'https://www.youtube.com/watch?v=pRXJ2PYu22Q&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=31',
            'https://www.youtube.com/watch?v=6_ntV0zBOMY&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=32',
            'https://www.youtube.com/watch?v=p36ehVmF0CM&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=33',
            'https://www.youtube.com/watch?v=IZ7GxYOsERk&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=34',
            'https://www.youtube.com/watch?v=rcu1pNqYn2Y&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=35',
            'https://www.youtube.com/watch?v=P2587FN4Y0w&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=36',
            'https://www.youtube.com/watch?v=hPwrbbgEBBY&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=37',
            'https://www.youtube.com/watch?v=whr14XxB8-M&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=38',
            'https://www.youtube.com/watch?v=cVd4ZCIXprs&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=39',
            'https://www.youtube.com/watch?v=8k4ctDmVn7w&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=40',
            'https://www.youtube.com/watch?v=7gte--l3Uxo&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=41',
            'https://www.youtube.com/watch?v=44PZFXaUa0U&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=42',
            'https://www.youtube.com/watch?v=jzBPa9VsTCI&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=43',
            'https://www.youtube.com/watch?v=kaHSVI4kDTc&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=44',
            'https://www.youtube.com/watch?v=nLxy0sqxhAc&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=45',
            'https://www.youtube.com/watch?v=m-AR6ly6eSU&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=46',
            'https://www.youtube.com/watch?v=sqM4Z8ok-IY&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=47',
            'https://www.youtube.com/watch?v=tDQeogR9KuQ&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=48',
            'https://www.youtube.com/watch?v=3Gj3Uo5h_XU&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=49',
            'https://youtube.com/watch?v=Ij0KypgKPNs&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=50',
            'https://www.youtube.com/watch?v=TFFrbW9_iZE&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=51',
            'https://www.youtube.com/watch?v=sGYEZvGu5ak&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=52',
            'https://www.youtube.com/watch?v=o_Plo5VllsA&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=53',
            'https://www.youtube.com/watch?v=jyKpMN3qogc&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=54',
            'https://www.youtube.com/watch?v=aeybVeBvN5M&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=55',
            'https://www.youtube.com/watch?v=JjIQq9lh-Bw&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=56',
            'https://www.youtube.com/watch?v=guQlKSs5Frc&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=57',
            'https://www.youtube.com/watch?v=ApXEw186rN0&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=58',
            'https://www.youtube.com/watch?v=yhpY9PrPoeQ&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=59',
            'https://www.youtube.com/watch?v=KLlJKJBjeUQ&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=60',
            'https://www.youtube.com/watch?v=PvI06_KTqnI&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=61',
            'https://www.youtube.com/watch?v=JOov7bMUxc8&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=62',
            'https://www.youtube.com/watch?v=4d3iBVOQajs&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=63',
            'https://www.youtube.com/watch?v=WJGmWdBEUxg&list=PL8p2I9GklV47eNpoo4Fr6fkags72a8F0v&index=64'

        ]
    },

};

const VideoPageAGD3 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageAGD3;
