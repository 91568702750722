import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=Osn2NHmaG8A&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=1',
            'https://www.youtube.com/watch?v=ItIBjI_luQs&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=2',
            'https://www.youtube.com/watch?v=9ebq4v-ppyA&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=3',
            'https://www.youtube.com/watch?v=pvS3xRTdX90&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=4',
            'https://www.youtube.com/watch?v=s9W7lgiwd5k&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=5',
            'https://www.youtube.com/watch?v=DiJmFIJD9HY&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=6',
            'https://www.youtube.com/watch?v=n_tYFhIqXMo&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=7',
            'https://www.youtube.com/watch?v=32IMwoVl0x4&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=8',
            'https://www.youtube.com/watch?v=J74iwmXPmCU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=9',
            'https://www.youtube.com/watch?v=laPhRWH53xQ&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=10',
            'https://www.youtube.com/watch?v=3-013oqKQJU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=11',
            'https://www.youtube.com/watch?v=AVikM1BabEw&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=12',
            'https://www.youtube.com/watch?v=EbeI4XhjV1o&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=13',
            'https://www.youtube.com/watch?v=kF-ksUtiNRM&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=14',
            'https://www.youtube.com/watch?v=Fxu4xaWUqrs&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=15',
            'https://www.youtube.com/watch?v=M2UbWYwZV1s&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=16',
            'https://www.youtube.com/watch?v=YhBoAHQVPnY&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=17',
            'https://www.youtube.com/watch?v=JTSEuNu1T7M&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=18',
            'https://www.youtube.com/watch?v=2PKhMgR-ZK4&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=19',
            'https://www.youtube.com/watch?v=4vJC5wKhvyI&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=20',
            'https://www.youtube.com/watch?v=IS2qeDaQxzU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=21',
            'https://www.youtube.com/watch?v=IuJtz70AdsI&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=22',
            'https://www.youtube.com/watch?v=8zHduWMsOi0&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=23',
            'https://www.youtube.com/watch?v=A0CH228KZ1o&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=24',
            'https://www.youtube.com/watch?v=gb1iIXQhC7A&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=25',
            'https://www.youtube.com/watch?v=_HbVzBp4yZ8&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=26',
            'https://www.youtube.com/watch?v=io5pim80fyM&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=27',
            'https://www.youtube.com/watch?v=zQTM7PcSYVI&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=28',
            'https://www.youtube.com/watch?v=SEs7yMyDSi4&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=29',
            'https://www.youtube.com/watch?v=wX2IE2mQMYU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=30',
            'https://www.youtube.com/watch?v=5d1tPdkzPek&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=31',
            'https://www.youtube.com/watch?v=vgNrpTBrSVQ&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=32',
            'https://www.youtube.com/watch?v=-ok3c9wPTMg&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=33',
            'https://www.youtube.com/watch?v=SatJdgYt_Ms&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=34',
            'https://www.youtube.com/watch?v=lkCVZ8JJoxo&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=35',
            'https://www.youtube.com/watch?v=wypJkP75ako&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=36',
            'https://www.youtube.com/watch?v=xXlB0GkBuq4&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=37',
            'https://www.youtube.com/watch?v=tjFQ9Psr0Bo&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=38',
            'https://www.youtube.com/watch?v=oemWs7Qqd5s&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=39',
            'https://www.youtube.com/watch?v=Ccv8XNSVS9s&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=40',
            'https://www.youtube.com/watch?v=IZQSIzvS1I4&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=41',
            'https://www.youtube.com/watch?v=8QvBORFU59Q&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=42',
            'https://www.youtube.com/watch?v=6m4lL6Lgc_Q&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=43',
            'https://www.youtube.com/watch?v=nmj4_ndaufY&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=44',
            'https://www.youtube.com/watch?v=tRXBQ2I16wA&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=45',
            'https://www.youtube.com/watch?v=ELbZAC_jEMM&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=46',
            'https://www.youtube.com/watch?v=jIgSYLWNXGY&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=47',
            'https://www.youtube.com/watch?v=K2W8zk-ij6A&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=48',
            'https://www.youtube.com/watch?v=S4R9enKLkaU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=49',
            'https://www.youtube.com/watch?v=J8vMVR8P4nU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=50',
            'https://www.youtube.com/watch?v=06qPXtlZZSI&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=51',
            'https://www.youtube.com/watch?v=0wNjZ88qlD4&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=52',
            'https://www.youtube.com/watch?v=IPIV6Zjz5NU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=53',
            'https://www.youtube.com/watch?v=NaBHR5-Ga-Q&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=54',
            'https://www.youtube.com/watch?v=rPPr5bqPYr0&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=55',
            'https://www.youtube.com/watch?v=r1inEDHwYQ4&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=56',
            'https://www.youtube.com/watch?v=zvmwfvQ3LJE&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=57',
            'https://www.youtube.com/watch?v=EGNkt5_YO60&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=58',
            'https://www.youtube.com/watch?v=wgCls1m2WAk&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=59',
            'https://www.youtube.com/watch?v=n3gbnI6NRz0&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=60',
            'https://www.youtube.com/watch?v=aJ6imF6o6ew&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=61',
            'https://www.youtube.com/watch?v=fytRy9UNhUM&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=62',
            'https://www.youtube.com/watch?v=J9ywPliCGOw&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=63',
            'https://www.youtube.com/watch?v=e4smryp-KP0&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=64',
            'https://www.youtube.com/watch?v=6oRjQtIPYdU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=65',
            'https://www.youtube.com/watch?v=5OdNC01Qb8U&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=66',
            'https://www.youtube.com/watch?v=PgWta0ounc0&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=67',
            'https://www.youtube.com/watch?v=eOFkUaW0K78&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=68',
            'https://www.youtube.com/watch?v=AYNqKS211p8&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=69',
            'https://www.youtube.com/watch?v=34wrlhZXM9U&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=70',
            'https://www.youtube.com/watch?v=Y2bczHrrMQg&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=71',
            'https://www.youtube.com/watch?v=Bwfeds3FdR0&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=72',
            'https://www.youtube.com/watch?v=SB3ZNw0N1qM&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=73',
            'https://www.youtube.com/watch?v=Smo-PJL3-BA&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=74',
            'https://www.youtube.com/watch?v=XlIqnRtOMX8&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=75',
            'https://www.youtube.com/watch?v=dUu6AtA0N6k&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=76',
            'https://www.youtube.com/watch?v=m0OMedQHBhc&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=77',
            'https://www.youtube.com/watch?v=4a_XGka-uYk&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=78',
            'https://www.youtube.com/watch?v=wCtFwNqUmZ0&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=79',
            'https://www.youtube.com/watch?v=0-Tl6Yf6wCs&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=80',
            'https://www.youtube.com/watch?v=Tp1Jd2vwL10&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=81',
            'https://www.youtube.com/watch?v=lnqulWrMHPQ&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=82',
            'https://www.youtube.com/watch?v=1Dh61ZaXloM&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=83',
            'https://www.youtube.com/watch?v=w5ufDir2eQc&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=84',
            'https://www.youtube.com/watch?v=lcO2athCAnU&list=PLdPwRNmUlk0lw0nkFgbqwuFHyprkCT0qz&index=85',
        ]
    },
};

const VideoPageRD5 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                      <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageRD5;