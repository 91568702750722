import axios from 'axios';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../component/ComponentPage.css';
import './SignUpPage.css';

function SignUpPage() {
    const [isRightPanelActive, setRightPanelActive] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
    });
    const [passwordData, setPasswordData] = useState({
        password: '',
        password_confirmation: ''
    });
    const [email, setEmail] = useState('');
    const { id, token } = useParams();  // Used for Reset Password URL params

    const [modalVisible, setModalVisible] = useState(false);   // send email
    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };
    const handleClickOutside = (event) => {
        if (event.target.id === 'id01') {
            setModalVisible(false);
        }
    };

    const handleSignUpClick = () => {
        setRightPanelActive(true);
    };

    const handleSignInClick = () => {
        setRightPanelActive(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData({
            ...passwordData,
            [name]: value
        });
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmitSignUp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8080/api/user/register', formData);
            alert(response.data.message);
            window.location.href = "/CoursePage";
        } catch (error) {
            console.error('There was an error with the registration!', error);
        }
    };

    const handleSubmitSignIn = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8080/api/user/login', {
                email: formData.email,
                password: formData.password
            });
            alert(response.data.message);
            window.location.href = "/CoursePage";
        } catch (error) {
            console.error('There was an error with the login!', error);
        }
    };

    // Handle Change Password
    const handleSubmitChangePassword = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'http://localhost:8080/api/user/changepassword',
                passwordData,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            alert(response.data.message);
        } catch (error) {
            console.error('Error changing password:', error);
        }
    };

    // Handle Send Reset Password Email
    const handleSubmitSendResetEmail = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8080/api/user/send-reset-password-email', { email });
            alert(response.data.message);
        } catch (error) {
            console.error('Error sending reset email:', error);
        }
    };

    // Handle Reset Password
    const handleSubmitResetPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`http://localhost:8080/api/user/reset-password/${id}/${token}`, passwordData);
            alert(response.data.message);
        } catch (error) {
            console.error('Error resetting password:', error);
        }
    };

    return (
        <>
            <div className={`sign_container ${isRightPanelActive ? 'right-panel-active' : ''}`} id="body-container">
                <div className="form-container sign-up-container">
                    <form onSubmit={handleSubmitSignUp} className='sign-form'>
                        <h1 className='sign_Heading'>Create Account</h1>
                        <div className="social-container">
                            <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                        <span className='sign-span'>or use your email for registration</span>
                        <input type="text" name="name" placeholder="Name" onChange={handleChange} />
                        <input type="email" name="email" placeholder="Email" onChange={handleChange} />
                        <input type="password" name="password" placeholder="Password" onChange={handleChange} />
                        <input type="password" name="password_confirmation" placeholder="Confirm Password" onChange={handleChange} />
                        <button type="submit">Sign Up</button>
                    </form>
                </div>
                <div className="form-container sign-in-container" >
                    <form onSubmit={handleSubmitSignIn} className='sign-form'>
                        <h1 className='sign_Heading'>Sign in</h1>
                        <div className="social-container">
                            <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                        <span className='sign-span'>or use your account</span>
                        <input type="email" name="email" placeholder="Email" onChange={handleChange} />
                        <input type="password" name="password" placeholder="Password" onChange={handleChange} />
                        <a href="#" onClick={toggleModal}>Forgot your password?</a>
                        <button type="submit">Sign In</button>
                    </form>
                </div>
                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-left">
                            <h1 className='sign_Heading'>Welcome Back!</h1>
                            <p className='sign_para'>To keep connected with us please login with your personal info</p>
                            <button className="ghost" id="signIn" onClick={handleSignInClick}>Sign In</button>
                        </div>
                        <div className="overlay-panel overlay-right">
                            <h1 className='sign_Heading'>Aminurmus Technology</h1>
                            <p className='sign_para'>Learn Grow Earn And Be an Industry Expert</p>
                            <button className="ghost" id="signUp" onClick={handleSignUpClick}>Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Change Password Form */}
            {/* <div className="form-container">
                <form onSubmit={handleSubmitChangePassword} className='sign-form'>
                    <h1 className='sign_Heading'>Change Password</h1>
                    <input type="password" name="password" placeholder="New Password" onChange={handlePasswordChange} />
                    <input type="password" name="password_confirmation" placeholder="Confirm New Password" onChange={handlePasswordChange} />
                    <button type="submit">Change Password</button>
                </form>
            </div> */}

            {/* Send Reset Password Email Form */}
            {/* <div className="form-container">
                <form onSubmit={handleSubmitSendResetEmail} className='sign-form'>
                    <h1 className='sign_Heading'>Send Password Reset Email</h1>
                    <input type="email" placeholder="Email" onChange={handleEmailChange} />
                    <button type="submit">Send Email</button>
                </form>
            </div> */}

            {/* Reset Password Form */}
            {/* {id && token && (
                <div className="form-container">
                    <form onSubmit={handleSubmitResetPassword} className='sign-form'>
                        <h1 className='sign_Heading'>Reset Password</h1>
                        <input type="password" name="password" placeholder="New Password" onChange={handlePasswordChange} />
                        <input type="password" name="password_confirmation" placeholder="Confirm New Password" onChange={handlePasswordChange} />
                        <button type="submit">Reset Password</button>
                    </form>
                </div>
            )} */}
            <div className='l_body'>
                {modalVisible && (
                    <div id="id01" className="l_modal" onClick={handleClickOutside}>
                        <form className="l_modal-content l_animate">
                            <h1 className='sign_Heading'>Send Password Reset Email</h1>
                            <div className="l_close_container">
                                <span onClick={toggleModal} className="l_close" title="Close Modal">
                                    &times;
                                </span>
                            </div>
                            <div className="l_container">
                                <label htmlFor="uname">
                                    <b>Email</b>
                                </label>
                                <input className='l_btn' type="text" placeholder="Enter Email" name="email" required />

                                {/* <label htmlFor="psw">
                                   <b>Password</b>
                                    </label>
                                    <input className='l_btn' type="password" placeholder="Enter Password" name="psw" required /> */}

                                <button type="submit">Send Email</button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
}

export default SignUpPage;






















// import axios from 'axios';
// import React, { useState } from 'react';
// import './SignUpPage.css';

// function SignUpPage() {
//     const [isRightPanelActive, setRightPanelActive] = useState(false);
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         password: '',
//         password_confirmation: ''
//     });

//     const handleSignUpClick = () => {
//         setRightPanelActive(true);
//     };

//     const handleSignInClick = () => {
//         setRightPanelActive(false);
//     };

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//     };

//     const handleSubmitSignUp = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://localhost:8080/api/user/register', formData);
//             alert(response.data.message);
//         } catch (error) {
//             console.error('There was an error with the registration!', error);
//         }
//     };

//     const handleSubmitSignIn = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://localhost:8080/api/user/login', {
//                 email: formData.email,
//                 password: formData.password
//             });
//             alert(response.data.message);
//         } catch (error) {
//             console.error('There was an error with the login!', error);
//         }
//     };

//     return (
//         <>
//             <div className={`sign_container ${isRightPanelActive ? 'right-panel-active' : ''}`} id="body-container">
//                 <div className="form-container sign-up-container">
//                     <form onSubmit={handleSubmitSignUp} className='sign-form'>
//                         <h1 className='sign_Heading'>Create Account</h1>
//                         <div className="social-container">
//                             <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
//                             <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
//                             <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
//                         </div>
//                         <span className='sign-span'>or use your email for registration</span>
//                         <input type="text" name="name" placeholder="Name" onChange={handleChange} />
//                         <input type="email" name="email" placeholder="Email" onChange={handleChange} />
//                         <input type="password" name="password" placeholder="Password" onChange={handleChange} />
//                         <input type="password" name="password_confirmation" placeholder="Confirm Password" onChange={handleChange} />
//                         <button type="submit">Sign Up</button>
//                     </form>
//                 </div>
//                 <div className="form-container sign-in-container">
//                     <form onSubmit={handleSubmitSignIn} className='sign-form'>
//                         <h1 className='sign_Heading'>Sign in</h1>
//                         <div className="social-container">
//                             <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
//                             <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
//                             <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
//                         </div>
//                         <span className='sign-span'>or use your account</span>
//                         <input type="email" name="email" placeholder="Email" onChange={handleChange} />
//                         <input type="password" name="password" placeholder="Password" onChange={handleChange} />
//                         <a href="#">Forgot your password?</a>
//                         <button type="submit">Sign In</button>
//                     </form>
//                 </div>
//                 <div className="overlay-container">
//                     <div className="overlay">
//                         <div className="overlay-panel overlay-left">
//                             <h1 className='sign_Heading'>Welcome Back!</h1>
//                             <p className='sing_para'>To keep connected with us please login with your personal info</p>
//                             <button className="ghost" id="signIn" onClick={handleSignInClick}>Sign In</button>
//                         </div>
//                         <div className="overlay-panel overlay-right">
//                             <h1 className='sign_Heading'>Aminurmus Technology</h1>
//                             <p className='sing_para'>Learn Grow Earn And Be a Industry Expert</p>
//                             <button className="ghost" id="signUp" onClick={handleSignUpClick}>Sign Up</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default SignUpPage;















// import React, { useState } from 'react';
// import './SignUpPage.css';

// function SignUpPage() {
//     const [isRightPanelActive, setRightPanelActive] = useState(false);

//     const handleSignUpClick = () => {
//         setRightPanelActive(true);
//     };

//     const handleSignInClick = () => {
//         setRightPanelActive(false);
//     };

//     return (
//         <>
//             <div className={`sign_container ${isRightPanelActive ? 'right-panel-active' : ''}`} id="body-container">
//                 <div className="form-container sign-up-container">
//                     <form action="#" className='sign-form'>
//                         <h1 className='sign_Heading'>Create Account</h1>
//                         <div className="social-container">
//                             <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
//                             <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
//                             <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
//                         </div>
//                         <span className='sign-span'>or use your email for registration</span>
//                         <input type="text" placeholder="Name" />
//                         <input type="email" placeholder="Email" />
//                         <input type="password" placeholder="Password" />
//                         <button type="submit">Sign Up</button>
//                     </form>
//                 </div>
//                 <div className="form-container sign-in-container">
//                     <form action="#" className='sign-form'>
//                         <h1 className='sign_Heading'>Sign in</h1>
//                         <div className="social-container">
//                             <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
//                             <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
//                             <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
//                         </div>
//                         <span className='sign-span'>or use your account</span>
//                         <input type="email" placeholder="Email" />
//                         <input type="password" placeholder="Password" />
//                         <a href="#">Forgot your password?</a>
//                         <button type="submit">Sign In</button>
//                     </form>
//                 </div>
//                 <div className="overlay-container">
//                     <div className="overlay">
//                         <div className="overlay-panel overlay-left">
//                             <h1 className='sign_Heading'>Welcome Back!</h1>
//                             <p className='sing_para'>To keep connected with us please login with your personal info</p>
//                             <button className="ghost" id="signIn" onClick={handleSignInClick}>Sign In</button>
//                         </div>
//                         <div className="overlay-panel overlay-right">
//                             <h1 className='sign_Heading'>Aminurmus Technology</h1>
//                             <p className='sing_para'>Learn Grow Earn And Be a Industry Expert</p>
//                             <button className="ghost" id="signUp" onClick={handleSignUpClick}>Sign Up</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default SignUpPage;
