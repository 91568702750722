import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to IOS',
        videoList: [
            'https://www.youtube.com/watch?v=Zu7E2VL2xa0&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=1',
            'https://www.youtube.com/watch?v=B1Ccb_jzwCo&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=2',
            'https://www.youtube.com/watch?v=xiRyyrde8Bg&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=3',
            'https://www.youtube.com/watch?v=v9bSQVNGZaY&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=4',
            'https://www.youtube.com/watch?v=UEtnDXq1bCo&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=5',
            'https://www.youtube.com/watch?v=DNgsN7KLTzI&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=6',
            'https://www.youtube.com/watch?v=pfUBDD7V9k0&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=7',
            'https://www.youtube.com/watch?v=5cHSNt31U98&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=8',
            'https://www.youtube.com/watch?v=_PJnyf5InGM&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=9',
            'https://www.youtube.com/watch?v=urgYiGDLIzw&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=10',
            'https://www.youtube.com/watch?v=LXk2iX08lkA&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=11',
            'https://www.youtube.com/watch?v=PNCQnSQU6ls&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=12',
            'https://www.youtube.com/watch?v=u-13r7sWJqs&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=13',
            'https://www.youtube.com/watch?v=QlwKcn1kOpE&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=14',
            'https://www.youtube.com/watch?v=r32qBRzz4lY&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=15',
            'https://www.youtube.com/watch?v=LMyUUKuMVKg&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=16',
            'https://www.youtube.com/watch?v=9ChbX8A4llM&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=17',
            'https://www.youtube.com/watch?v=9aFGzyv7_5I&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=18',
            'https://www.youtube.com/watch?v=1yFzaavgmk0&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=19',
            'https://www.youtube.com/watch?v=EkKdQ6gVzao&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=20',
            'https://www.youtube.com/watch?v=5Q4xDyXmEP4&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=21',
            'https://www.youtube.com/watch?v=NG2tLeUTH24&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=22',
            'https://www.youtube.com/watch?v=-PzlpNaszp4&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=23',
            'https://www.youtube.com/watch?v=hL8PbCdaT9c&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=24',
            'https://www.youtube.com/watch?v=xEXWwPBlIDY&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=25',
            'https://www.youtube.com/watch?v=wSz-Wi7w0tk&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=26',
            'https://www.youtube.com/watch?v=1J0X4HKs2W0&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=27',
            'https://www.youtube.com/watch?v=WlWbLT01-tM&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=28',
            'https://www.youtube.com/watch?v=1KZEyBQpw3M&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=29',
            'https://www.youtube.com/watch?v=lng84jdPLBc&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=30',
            'https://www.youtube.com/watch?v=jaagKolaDSA&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=31',
            'https://www.youtube.com/watch?v=-Wwa-8X852g&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=32',
            'https://www.youtube.com/watch?v=En7VoKWcRWg&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=33',
            'https://www.youtube.com/watch?v=T_KlkpwspR0&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=34',
            'https://www.youtube.com/watch?v=Yo4FBu8nQxA&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=35',
            'https://www.youtube.com/watch?v=nsZYYlDmj7o&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=36',
            'https://www.youtube.com/watch?v=l0wcAOwSNxI&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=37',
            'https://www.youtube.com/watch?v=i8h1PlxLNHw&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=38',
            'https://www.youtube.com/watch?v=Z7DAz-PFsU4&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=39',
            'https://www.youtube.com/watch?v=iQnYFy85aTA&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=40',
            'https://www.youtube.com/watch?v=cdtGTaoJfNY&list=PLtCBuHKmdxOcmrDx2pM4qNvzWF2NI_Qlo&index=41',
        ]
    },
    
};

const VideoPageIOS6 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>IOS Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 IOS Bootcamp</h1>
                    <p>Become a App degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageIOS6;