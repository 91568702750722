import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=eCU7FfMl5WU&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=1',
            'https://www.youtube.com/watch?v=lHOb0BWu3-M&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=2',
            'https://www.youtube.com/watch?v=lf8giXzuxVE&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=3',
            'https://www.youtube.com/watch?v=XGo8bz_j2ZY&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=4',
            'https://www.youtube.com/watch?v=2xz-ADKsKzA&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=5',
            'https://www.youtube.com/watch?v=4LI9sv8r6ss&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=6',
            'https://www.youtube.com/watch?v=O1yXbmz409c&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=7',
            'https://www.youtube.com/watch?v=gcOac4VFAvo&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=8',
            'https://www.youtube.com/watch?v=Tu9_vmzEK9w&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=9',
            'https://www.youtube.com/watch?v=NmSh-YpmDU4&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=10',
            'https://www.youtube.com/watch?v=0B6_GWvT2vs&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=11',
            'https://www.youtube.com/watch?v=eLT2uiV7D8g&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=12',
            'https://www.youtube.com/watch?v=aAcI_FdfkA8&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=13',
            'https://www.youtube.com/watch?v=Kduq5HxJdj4&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=14',
            'https://www.youtube.com/watch?v=EeOPyXwJiXk&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=15',
            'https://www.youtube.com/watch?v=pX0SBJF01EU&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=16',
            'https://www.youtube.com/watch?v=SwviLVyaRKU&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=17',
            'https://www.youtube.com/watch?v=vJLW3S_97aQ&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=18',
            'https://www.youtube.com/watch?v=8hZg3Rw0X7w&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=19',
            'https://www.youtube.com/watch?v=4qoqhxlYI5w&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=20',
            'https://www.youtube.com/watch?v=FVsnolBvRBc&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=21',
            'https://www.youtube.com/watch?v=ZG0_q4i8ADs&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=22',
            'https://www.youtube.com/watch?v=tZqdubin95M&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=23',
            'https://www.youtube.com/watch?v=DGgeeY15X4k&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=24',
            'https://www.youtube.com/watch?v=e7llTS5U0dM&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=25',
            'https://www.youtube.com/watch?v=FM_GpaW3_Iw&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=26',
            'https://www.youtube.com/watch?v=TtVMerlZtrE&list=PLRAV69dS1uWQos1M1xP6LWN6C-lZvpkmq&index=27',
        ]
    },
};

const VideoPageRD2 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageRD2;