import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=I3jCsVgf564&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=1',
            'https://www.youtube.com/watch?v=D4iVxz_1utY&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=2',
            'https://www.youtube.com/watch?v=-ycs3o8DZ4g&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=3',
            'https://www.youtube.com/watch?v=dbcuhULYJMs&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=4',
            'https://www.youtube.com/watch?v=On7Q20rwin0&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=5',
            'https://www.youtube.com/watch?v=4ZfJ1QdbSWA&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=6',
            'https://www.youtube.com/watch?v=aLuUIFA5Q_A&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=7',
            'https://www.youtube.com/watch?v=I27b8OvWX6s&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=8',
            'https://www.youtube.com/watch?v=mh1MZfC0uA8&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=9',
            'https://www.youtube.com/watch?v=2aBkuhpoSpo&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=10',
            'https://www.youtube.com/watch?v=8JD7vpXOWdU&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=11',
            'https://www.youtube.com/watch?v=o6riatmDeuo&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=12',
            'https://www.youtube.com/watch?v=jQfEVHcN3v0&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=13',
            'https://www.youtube.com/watch?v=i4QA1wPqey8&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=14',
            'https://www.youtube.com/watch?v=sYDj_GitvcQ&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=15',
            'https://www.youtube.com/watch?v=zNsLxGbJt-A&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=16',
            'https://www.youtube.com/watch?v=mnWGviS3bTg&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=17',
            'https://www.youtube.com/watch?v=H5LJ6YuVe_4&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=18',
            'https://www.youtube.com/watch?v=FGafMjbyGB0&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=19',
            'https://www.youtube.com/watch?v=AqQ8Srglwyw&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=20',
            'https://www.youtube.com/watch?v=5U5uLN345gI&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=21',
            'https://www.youtube.com/watch?v=Ctp5lWWKMSQ&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=22',
            'https://www.youtube.com/watch?v=Ctp5lWWKMSQ&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=23',
            'https://www.youtube.com/watch?v=z28D7Z8zcTo&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=24',
            'https://www.youtube.com/watch?v=eiSy-vhkk98&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=25',
            'https://www.youtube.com/watch?v=l2rwC8tKVJM&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=26',
            'https://www.youtube.com/watch?v=Dyt4nxrjCg4&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=27',
            'https://www.youtube.com/watch?v=0nXRzzxSEqk&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=28',
            'https://www.youtube.com/watch?v=z_iYCEpQWsc&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=29',
            'https://www.youtube.com/watch?v=Z70T9nWLR5g&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=30',
            'https://www.youtube.com/watch?v=IM_vckM38Zw&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=31',
            'https://www.youtube.com/watch?v=wBpM9VPBCF4&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=32',
            'https://www.youtube.com/watch?v=36CPVMULZuc&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=33',
            'https://www.youtube.com/watch?v=ixCncxA_cqY&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=34',
            'https://www.youtube.com/watch?v=Hm37OiYib6k&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=35',
            'https://www.youtube.com/watch?v=DJmXGf9qGPA&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=36',
            'https://www.youtube.com/watch?v=CyWFoRSAtv0&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=37',
            'https://www.youtube.com/watch?v=kS74JY9amPc&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=38',
            'https://www.youtube.com/watch?v=RC3z4FwtAp0&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=39',
            'https://www.youtube.com/watch?v=4njG2CzRxrI&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=40',
            'https://www.youtube.com/watch?v=iIvImz0n6ok&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=41',
            'https://www.youtube.com/watch?v=dK9dGasxw9E&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=42',
            'https://www.youtube.com/watch?v=LExwf83yH1c&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=43',
            'https://www.youtube.com/watch?v=LExwf83yH1c&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=43',
            'https://www.youtube.com/watch?v=aj21AuG3vRU&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=45',
            'https://www.youtube.com/watch?v=VxbkxoTa7aE&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=46',
            'https://www.youtube.com/watch?v=UYJ1UGWRnFI&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=47',
            'https://www.youtube.com/watch?v=-IClQZozEaM&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=48',
            'https://www.youtube.com/watch?v=0zd2jg6zdY4&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=49',
            'https://www.youtube.com/watch?v=J-1Is13MKPw&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=50',
            'https://www.youtube.com/watch?v=PCSs0RVIKoo&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=51',
            'https://www.youtube.com/watch?v=SfTuxHCgbf4&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=52',
            'https://www.youtube.com/watch?v=c7kC2tF-YnA&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=53',
            'https://www.youtube.com/watch?v=GvraqWq5NqI&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=54',
            'https://www.youtube.com/watch?v=rmdr8CloYQY&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=55',
            'https://www.youtube.com/watch?v=lM98qQ0wSS8&list=PLhFBHuT4t3aBL59pIGo13H4tUVzS_GlYm&index=56',
        ]
    },
};

const VideoPageRD10 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageRD10;