import React from 'react';
import girlImage from "../../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=Jo9yksmQRrk&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=1',
            'https://www.youtube.com/watch?v=muUvsMblcRM&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=2',
            'https://www.youtube.com/watch?v=lceG4iKNfEg&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=3',
            'https://www.youtube.com/watch?v=3cVm6gmORrU&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=4',
            'https://www.youtube.com/watch?v=blXodsD1fT0&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=5',
            'https://www.youtube.com/watch?v=sjrB7W721Cc&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=6',
            'https://www.youtube.com/watch?v=IIAPYSsZQ4U&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=7',
            'https://www.youtube.com/watch?v=MOjluUqDD6k&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=8',
            'https://www.youtube.com/watch?v=3-Vv3HLX2OU&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=9',
            'https://www.youtube.com/watch?v=K0f2Lb5yGsk&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=10',
            'https://www.youtube.com/watch?v=195RY7jCuZg&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=11',
            'https://www.youtube.com/watch?v=N5xUWjUrdEE&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=12',
            '',
            'https://www.youtube.com/watch?v=wFGs32c05Gg&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=14',
            'https://www.youtube.com/watch?v=40DPev7g5Ps&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=15',
            'https://www.youtube.com/watch?v=1h73MOcH3xA&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=16',
            'https://www.youtube.com/watch?v=zi_zhrg3AV4&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=17',
            'https://www.youtube.com/watch?v=KfgS6zCTMrA&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=18',
            'https://www.youtube.com/watch?v=nthq56UABI0&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=19',
            'https://www.youtube.com/watch?v=E4uQ36KQLPU&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=20',
            'https://www.youtube.com/watch?v=iP5hm2LTxLU&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=21',
            'https://www.youtube.com/watch?v=qiW2qKVGT6k&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=22',
            'https://www.youtube.com/watch?v=nz3ZJ8IU-lo&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=23',
            'https://www.youtube.com/watch?v=R8Vz8p2w7Oo&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=24',
            'https://www.youtube.com/watch?v=PeGfX7W1mJk&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=25',
            'https://www.youtube.com/watch?v=d4LdRk7PI6E&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=26',
            'https://www.youtube.com/watch?v=IgYJa2FNozo&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=27',
            'https://www.youtube.com/watch?v=esbdyyEvkxw&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=28',
            'https://www.youtube.com/watch?v=SwFrRteJFfg&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=29',
            'https://www.youtube.com/watch?v=B2mUIqXJEvM&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=30',
            'https://www.youtube.com/watch?v=XfiyjRGVVSo&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=31',
            'https://www.youtube.com/watch?v=48YwaTiYau0',
            'https://www.youtube.com/watch?v=mYp11gGzgmQ&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=33',
            'https://www.youtube.com/watch?v=B9mhOBAbsuo&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=34',
            'https://www.youtube.com/watch?v=oE0v0wfX2AQ&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=35',
            'https://www.youtube.com/watch?v=YRM2TYin2Oo&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=36',
            'https://www.youtube.com/watch?v=Vpw2KowuE5Q&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=37',
            'https://www.youtube.com/watch?v=_6Tl2_eM0DE&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=38',
            'https://www.youtube.com/watch?v=JWvGBn3oTU4&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=39',
            'https://www.youtube.com/watch?v=JWvGBn3oTU4&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=40',
            'https://www.youtube.com/watch?v=JgXXwyb9aNA&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=41',
            'https://www.youtube.com/watch?v=cPUwVM5_NKE&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=42',
            'https://www.youtube.com/watch?v=CBf0BOaIBVw&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=43',
            'https://www.youtube.com/watch?v=uaPUVFBF-aY&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=44',
            'https://www.youtube.com/watch?v=mWJNOx3c0kI&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=45',
            'https://www.youtube.com/watch?v=AIjySiuLUq8&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=46',
            'https://www.youtube.com/watch?v=XzjxqS0xAu0&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=47',
            'https://www.youtube.com/watch?v=gI_JbSPmLrc&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=48',
            'https://www.youtube.com/watch?v=d6xn5uflUjg&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=49',
            'https://www.youtube.com/watch?v=5IanQIwhA4E&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=50',
            'https://www.youtube.com/watch?v=Gq367U3-abc&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=51',
            'https://www.youtube.com/watch?v=48YwaTiYau0&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=52',
            'https://www.youtube.com/watch?v=vgqBc7jni8c&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=53',
            'https://www.youtube.com/watch?v=D1pO4AQTfes&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=54',
            'https://www.youtube.com/watch?v=DI4Srgk0bMY&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=55',
            'https://www.youtube.com/watch?v=9jp_1gHhieA&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=56',
            'https://www.youtube.com/watch?v=HZuk6Wkx_Eg&list=PLwStLOWnW4dynv5J6mQGh-glcedsL-tq2&index=57',
        ]
    },
    
};

const VideoPageUI9 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageUI9;