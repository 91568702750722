import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=qoSksQ4s_hg&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET',
            'https://www.youtube.com/watch?v=VB7y0yxZjro&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=2',
            'https://www.youtube.com/watch?v=ranSYb-EhrU&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=3',
            'https://www.youtube.com/watch?v=GCcfKNswi4c&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=4',
            'https://www.youtube.com/watch?v=czlwRUeTCgw&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=5',
            'https://www.youtube.com/watch?v=JzZFccCEgGA&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=6',
            'https://www.youtube.com/watch?v=QLatPwsbDrQ&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=7',
            'https://www.youtube.com/watch?v=u0Mq3FzpsmI&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=8',
            'https://www.youtube.com/watch?v=_MC0Gw07w8M&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=9',
            'https://www.youtube.com/watch?v=Z1eV0RBRam0&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=10',
            'https://www.youtube.com/watch?v=tH-q9QFNUdA&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=11',
            'https://www.youtube.com/watch?v=B4ZCFdrBmbE&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=12',
            'https://www.youtube.com/watch?v=Lp-Du2fKoug&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=13',
            'https://www.youtube.com/watch?v=1v1Bk3Q02Sc&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=14',
            'https://www.youtube.com/watch?v=LjGaaWX_NbE&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=15',
            'https://www.youtube.com/watch?v=mbT7sSmVUS8&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=16',
            'https://www.youtube.com/watch?v=PpbFyLTtpWI&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=17',
            'https://www.youtube.com/watch?v=U87UmD-5h4o&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=18',
            'https://www.youtube.com/watch?v=QSuTH0C_3_Y&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=19',
            'https://www.youtube.com/watch?v=eZBTLsv2ktM&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=20',
            'https://www.youtube.com/watch?v=KH57lIgwe2g&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=21',
            'https://www.youtube.com/watch?v=kjdZ1h3CIs4&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=22',
            'https://www.youtube.com/watch?v=TD3Pfuxgnuc&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=23',
            'https://www.youtube.com/watch?v=0ZiltZDg9Gg&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=24',
            'https://www.youtube.com/watch?v=k8MIbEVXhE0&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=25',
            'https://www.youtube.com/watch?v=HEdikBHsMag&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=26',
            'https://www.youtube.com/watch?v=EUnV-fCY0Pc&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=27',
            'https://www.youtube.com/watch?v=t-evBCCQFzM&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=28',
            'https://www.youtube.com/watch?v=wA5vU_HQfVI&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=29',
            'https://www.youtube.com/watch?v=yVdU2coJ1VQ&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=30',
            'https://www.youtube.com/watch?v=SrhHszXkMRU&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=31',
            'https://www.youtube.com/watch?v=irrxnH-nkqg&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=32',
            'https://www.youtube.com/watch?v=H63dVFDuJDM&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=33',
            'https://www.youtube.com/watch?v=SowaJlX1uKA&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=34',
            'https://www.youtube.com/watch?v=FQuwArzGPYo&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=35',
            'https://www.youtube.com/watch?v=V0S0LXvnW-o&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=36',
            'https://www.youtube.com/watch?v=97agDBCyxAE&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=37',
            'https://www.youtube.com/watch?v=TrGI9Yki-24&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=38',
            'https://www.youtube.com/watch?v=rBGgguNnutE&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=39',
            'https://www.youtube.com/watch?v=bf_9IeihQ0M&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=40',
            'https://www.youtube.com/watch?v=XQEfWd1lh4Q&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=41',
            'https://www.youtube.com/watch?v=RVA4HoEE_q8&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=42',
            'https://www.youtube.com/watch?v=Az5J_EkhYCY&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=43',
            'https://www.youtube.com/watch?v=BhlvQfIaE4k&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=44',
            'https://www.youtube.com/watch?v=b0pxAb_yy2U&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=45',
            'https://www.youtube.com/watch?v=uq7omoxwA7A&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=46',
            'https://www.youtube.com/watch?v=Qikr22Tc3wg&list=PL4cUxeGkcC9i9Ae2D9Ee1RvylH38dKuET&index=47'

        ]
    },
};

const VideoPageJS4 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageJS4;