import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=ChVE-JbtYbM&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=1',
            'https://www.youtube.com/watch?v=1CgJPjGzFII&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=2',
            'https://www.youtube.com/watch?v=sfMNI0yLZII&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=3',
            'https://www.youtube.com/watch?v=6WG6w3ipc9U&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=4',
            'https://www.youtube.com/watch?v=Xl3msGMwPp0&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=5',
            'https://www.youtube.com/watch?v=TVeH7YkH9LU&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=6',
            'https://www.youtube.com/watch?v=Exbe46JuTe8&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=7',
            'https://www.youtube.com/watch?v=vnRsFtwWu8M&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=8',
            'https://www.youtube.com/watch?v=4lSykV_rhBo&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=9',
            'https://www.youtube.com/watch?v=bQApV_RddO4&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=10',
            'https://www.youtube.com/watch?v=qkMJL0FwiyE&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=11',
            'https://www.youtube.com/watch?v=aXuLsfq4cpE&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=12',
            'https://www.youtube.com/watch?v=v_Ewns3n_Ow&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=13',
            'https://www.youtube.com/watch?v=U-S3rgG8hBE&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=14',
            'https://www.youtube.com/watch?v=5e4LCRoCVA4&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=15',
            'https://www.youtube.com/watch?v=GeXA_MzMR6I&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=16',
            'https://www.youtube.com/watch?v=h6Gks3DjFKg&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=17',
            'https://www.youtube.com/watch?v=W-HQC_YUGBY&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=18',
            'https://www.youtube.com/watch?v=qfN6c5FEAQQ&list=PL2PkZdv6p7Zmu51_FBoNDcv8Bk8bXODvH&index=19',
        ]
    },
    
};

const VideoPageBKD1 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageBKD1;