import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=0eWrpsCLMJQ&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ',
            'https://www.youtube.com/watch?v=FVPGJHinItI&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=2',
            'https://www.youtube.com/watch?v=mDoHtD1hI3I&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=3',
            'https://www.youtube.com/watch?v=16rQyEQtpyQ&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=4',
            'https://www.youtube.com/watch?v=2a6OfacW_-I&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=5',
            'https://www.youtube.com/watch?v=N8FBmB2jme8&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=6',
            'https://www.youtube.com/watch?v=Y6OP-lPJxgs&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=7',
            'https://www.youtube.com/watch?v=q256X6-u9Q8&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=8',
            'https://www.youtube.com/watch?v=ZfIc1_oj7uM&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=9',
            'https://www.youtube.com/watch?v=Oo0-r_YhoJs&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=10',
            'https://www.youtube.com/watch?v=DOWwWsbG1Sw&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=11',
            'https://www.youtube.com/watch?v=nWst87nQmZQ&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=12',
            'https://www.youtube.com/watch?v=WiDn2y1Ktws&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=13',
            'https://www.youtube.com/watch?v=Du3p6QYGs3A&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=14',
            'https://www.youtube.com/watch?v=BGy8DdGw_WE&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=15',
            'https://www.youtube.com/watch?v=y8lwG8IM82k&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=16',
            'https://www.youtube.com/watch?v=pwuGBvOPFYI&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=17',
            'https://www.youtube.com/watch?v=OFPIGlxunL0&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=18',
            'https://www.youtube.com/watch?v=69VeYoKzL6I&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=19',
            'https://www.youtube.com/watch?v=vj0kUzKOgGQ&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=20',
            'https://www.youtube.com/watch?v=LmIsbzt-S_E&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=21',
            'https://www.youtube.com/watch?v=TmTGQiLBS5A&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=22',
            'https://www.youtube.com/watch?v=Nehk4tBxD4o&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=23',
            'https://www.youtube.com/watch?v=QC6wRxXT26I&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=24',
            'https://www.youtube.com/watch?v=qh5nHv-4aw0&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=25',
            'https://www.youtube.com/watch?v=KRV9jZwl0Ig&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=26',
            'https://www.youtube.com/watch?v=gnTFkl2AF-w&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=27',
            'https://www.youtube.com/watch?v=qktmk-7Kot8&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=28',
            'https://www.youtube.com/watch?v=ZoeZxpfTCXk&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=29',
            'https://www.youtube.com/watch?v=RkV_LozGP3w&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=30',
            'https://www.youtube.com/watch?v=ScaKGrW5s0I&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=31',
            'https://www.youtube.com/watch?v=gRgdoCnEMHI&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=32',
            'https://www.youtube.com/watch?v=nGr3C3wbh9c&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=33',
            'https://www.youtube.com/watch?v=1Zx2xb7F-c8&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=34',
            'https://www.youtube.com/watch?v=GKU0LtH8bzU&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=35',
            'https://www.youtube.com/watch?v=xqqCPoEyoE0&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=36',
            'https://www.youtube.com/watch?v=iyabqUWYsz4&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=37',
            'https://www.youtube.com/watch?v=2cWPk2X79is&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=38',
            'https://www.youtube.com/watch?v=WyWJwR0FJV0&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=39',
            'https://www.youtube.com/watch?v=kFHkztHm8xA&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=40',
            'https://www.youtube.com/watch?v=U8FfRRLFmSY&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=41',
            'https://www.youtube.com/watch?v=Ikn9eWLw3ys&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=42',
            'https://www.youtube.com/watch?v=kiR3J3MoWV4&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=43',
            'https://www.youtube.com/watch?v=lmteIO2FQYA&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=44',
            'https://www.youtube.com/watch?v=jwA-9XXybdM&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=45',
            'https://www.youtube.com/watch?v=_sJHvvgfE9o&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=46',
            'https://www.youtube.com/watch?v=3MLQ9Eb3QQE&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=47',
            'https://www.youtube.com/watch?v=lgatKVd03_U&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=48',
            'https://www.youtube.com/watch?v=ruE1beZ7a0g&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=49',
            'https://www.youtube.com/watch?v=-7hS1mZKT2Y&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=50',
            'https://www.youtube.com/watch?v=B0a3IIQckBw&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=51',
            'https://www.youtube.com/watch?v=VWmvobkOjcU&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=52',
            'https://www.youtube.com/watch?v=3_dFnULt3FA&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=53',
            'https://youtube.com/watch?v=ZQroyD2rLzY&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=54',
            'https://www.youtube.com/watch?v=nm-x8gsqB2E&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=55',
            'https://www.youtube.com/watch?v=dlVVYmntDnE&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=56',
            'https://www.youtube.com/watch?v=7y6GV3EeMHE&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=57',
            'https://www.youtube.com/watch?v=4nJJQMxZkF0&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=58',
            'https://www.youtube.com/watch?v=bHcQx4hCF_0&list=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&index=59'

        ]
    },

};

const VideoPageAGD1 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageAGD1;
