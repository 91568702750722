import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=TDSbNFt9XpE&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0',
            'https://www.youtube.com/watch?v=1SDu86W85u4&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=2',
            'https://www.youtube.com/watch?v=pVk5TZHBtNg&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=3',
            'https://www.youtube.com/watch?v=hWVbsJkxYEs&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=4',
            'https://www.youtube.com/watch?v=d-uEjoYyrA8&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=5',
            'https://www.youtube.com/watch?v=RJY5rnxXmMY&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=6',
            'https://www.youtube.com/watch?v=noRJ4SpAZ-E&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=7',
            'https://www.youtube.com/watch?v=uJIbc2YE58E&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=8',
            'https://www.youtube.com/watch?v=BnmRZ5t8uEU&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=9',
            'https://www.youtube.com/watch?v=VRQi3pQS5qA&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=10',
            'https://www.youtube.com/watch?v=N92gsOR5_FE&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=11',
            'https://www.youtube.com/watch?v=uOQLpBzzFww&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=12',
            'https://www.youtube.com/watch?v=t3rh6gTIYsI&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=13',
            'https://www.youtube.com/watch?v=2aNvg4gw3Ds&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=14',
            'https://www.youtube.com/watch?v=IKyVgsilFOw&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=15',
            'https://www.youtube.com/watch?v=EZmf0uxBtT8&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=16',
            'https://www.youtube.com/watch?v=m7Ux2mgou0A&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=17',
            'https://www.youtube.com/watch?v=OV7RtFHEb88&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=18',
            'https://www.youtube.com/watch?v=Njg1pLEuzXQ&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=19',
            'https://www.youtube.com/watch?v=dddL-62a7lE&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=20',
            'https://www.youtube.com/watch?v=0YpY8-FLUIE&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=21',
            'https://www.youtube.com/watch?v=ww3CTRy225I&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=22',
            'https://www.youtube.com/watch?v=HuhE-m3VFEA&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=23',
            'https://www.youtube.com/watch?v=3ftHliVF0F0&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=24',
            'https://www.youtube.com/watch?v=7mfrRRNx6Cg&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=25',
            'https://www.youtube.com/watch?v=ATdf91NWnVg&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=26',
            'https://www.youtube.com/watch?v=0nskK4D869Y&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=27',
            'https://www.youtube.com/watch?v=7Un1mGZDM3U&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=28',
            'https://www.youtube.com/watch?v=SD6Idtz-o9M&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=29',
            'https://www.youtube.com/watch?v=I9xE9Tlbxos&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=30',
            'https://www.youtube.com/watch?v=mZcD5VvDtog&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=31',
            'https://www.youtube.com/watch?v=4qiUeaaLGME&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=32',
            'https://www.youtube.com/watch?v=KEGk1nF4UHk&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=33',
            'https://www.youtube.com/watch?v=nTuKL4I-Q7Q&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=34',
            'https://www.youtube.com/watch?v=xGH3WrGbpfU&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=35',
            'https://www.youtube.com/watch?v=0fPpg3wh0cg&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=36',
            'https://www.youtube.com/watch?v=5GZC4lm6enU&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=37',
            'https://www.youtube.com/watch?v=08bz3pTMHZY&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=38',
            'https://www.youtube.com/watch?v=cp9tzzD7LHw&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=39',
            'https://www.youtube.com/watch?v=W_riafgSc98&list=PLp50dWW_m40W6u4NxWCsQk1F8CmzBI6r0&index=40'

        ]
    },

};

const VideoPageAGD2 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageAGD2;
