import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=GvBTu50qSDQ&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=1',
            'https://www.youtube.com/watch?v=TA1Kpn-0vCY&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=2',
            'https://www.youtube.com/watch?v=ylcJJR8EdmY&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=3',
            'https://www.youtube.com/watch?v=BLR5GdxUYx4&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=4',
            'https://www.youtube.com/watch?v=lc0SwYI1rX4&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=5',
            'https://www.youtube.com/watch?v=7SNJamxhCZk&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=6',
            'https://www.youtube.com/watch?v=dqc1X4ipNWk&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=7',
            'https://www.youtube.com/watch?v=P2wSM6XO8o8&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=8',
            'https://www.youtube.com/watch?v=2jb12csx96s&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=9',
            'https://www.youtube.com/watch?v=sXaK4-oVWco&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=10',
            'https://www.youtube.com/watch?v=KytEgER4KvI&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=11',
            'https://www.youtube.com/watch?v=B9Wr1o50ns0&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=12',
            'https://www.youtube.com/watch?v=t63hATmSgmo&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=13',
            'https://www.youtube.com/watch?v=OzFqYtXoVoA&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=14',
            'https://www.youtube.com/watch?v=fv48CwNoNcc&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=15',
            'https://www.youtube.com/watch?v=lbTC4l_oiZk&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=16',
            'https://www.youtube.com/watch?v=vgX8L0E1Trk&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=17',
            'https://www.youtube.com/watch?v=jssIzqmkSW8&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=18',
            'https://www.youtube.com/watch?v=H9tVm3fxpNQ&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=19',
            'https://www.youtube.com/watch?v=JGbI0YCoj0U&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=20',
            'https://www.youtube.com/watch?v=vcoRAWGEQpQ&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=21',
            'https://www.youtube.com/watch?v=ynI8NkE_H1s&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=22',
            'https://www.youtube.com/watch?v=1yIOfQr0LAY&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=23',
            'https://www.youtube.com/watch?v=ZodeOYF5iM0&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=24',
            'https://www.youtube.com/watch?v=JbfISWxOOuc&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=25',
            'https://www.youtube.com/watch?v=nwtWIeIzyl8&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=26',
            'https://www.youtube.com/watch?v=MJ2CVSJQG3s&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=27',
            'https://www.youtube.com/watch?v=JXyAw_CZ-P4&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=28',
            'https://www.youtube.com/watch?v=Si9CC1u0KZE&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=29',
            'https://www.youtube.com/watch?v=6ntFkaSyEOg&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=30',
            'https://www.youtube.com/watch?v=gcppNX8USV0&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=31',
            'https://www.youtube.com/watch?v=HMzB3zGEuqE&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=32',
            'https://www.youtube.com/watch?v=Lly6Hxg_1kk&list=PLVlQHNRLflP8kPvCvEM7ZopbRB93joJXy&index=33',
        ]
    },
};

const VideoPageJD5 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageJD5;