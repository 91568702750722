import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutUsPage from './learningPaltform/aboutUspage/AboutUsPage';
import ComponentPage from './learningPaltform/component/ComponentPage';
import ContectPage from './learningPaltform/contectpage/ContectPage';
import CoursePage from './learningPaltform/coursespage/CoursePage';
import FooterPage from './learningPaltform/footerpage/FooterPage';
import HomePage from './learningPaltform/homepages/HomePage';
import SignUpPage from './learningPaltform/signUppage/SignUpPage';
import Testimonial from './learningPaltform/testimonial/Testimonial';
import VideoPageAND1 from './learningPaltform/videopage/Android_Development/VideoPageAND1';
import VideoPageAND10 from './learningPaltform/videopage/Android_Development/VideoPageAND10';
import VideoPageAND11 from './learningPaltform/videopage/Android_Development/VideoPageAND11';
import VideoPageAND2 from './learningPaltform/videopage/Android_Development/VideoPageAND2';
import VideoPageAND3 from './learningPaltform/videopage/Android_Development/VideoPageAND3';
import VideoPageAND4 from './learningPaltform/videopage/Android_Development/VideoPageAND4';
import VideoPageAND5 from './learningPaltform/videopage/Android_Development/VideoPageAND5';
import VideoPageAND6 from './learningPaltform/videopage/Android_Development/VideoPageAND6';
import VideoPageAND7 from './learningPaltform/videopage/Android_Development/VideoPageAND7';
import VideoPageAND8 from './learningPaltform/videopage/Android_Development/VideoPageAND8';
import VideoPageAND9 from './learningPaltform/videopage/Android_Development/VideoPageAND9';
import VideoPageAGD1 from './learningPaltform/videopage/Angular_Development/VideoPageAGD1';
import VideoPageAGD10 from './learningPaltform/videopage/Angular_Development/VideoPageAGD10';
import VideoPageAGD11 from './learningPaltform/videopage/Angular_Development/VideoPageAGD11';
import VideoPageAGD2 from './learningPaltform/videopage/Angular_Development/VideoPageAGD2';
import VideoPageAGD3 from './learningPaltform/videopage/Angular_Development/VideoPageAGD3';
import VideoPageAGD4 from './learningPaltform/videopage/Angular_Development/VideoPageAGD4';
import VideoPageAGD5 from './learningPaltform/videopage/Angular_Development/VideoPageAGD5';
import VideoPageAGD6 from './learningPaltform/videopage/Angular_Development/VideoPageAGD6';
import VideoPageAGD7 from './learningPaltform/videopage/Angular_Development/VideoPageAGD7';
import VideoPageAGD8 from './learningPaltform/videopage/Angular_Development/VideoPageAGD8';
import VideoPageAGD9 from './learningPaltform/videopage/Angular_Development/VideoPageAGD9';
import VideoPageBKD1 from './learningPaltform/videopage/Backend_Development/VideoPageBKD1';
import VideoPageBKD10 from './learningPaltform/videopage/Backend_Development/VideoPageBKD10';
import VideoPageBKD11 from './learningPaltform/videopage/Backend_Development/VideoPageBKD11';
import VideoPageBKD2 from './learningPaltform/videopage/Backend_Development/VideoPageBKD2';
import VideoPageBKD3 from './learningPaltform/videopage/Backend_Development/VideoPageBKD3';
import VideoPageBKD4 from './learningPaltform/videopage/Backend_Development/VideoPageBKD4';
import VideoPageBKD5 from './learningPaltform/videopage/Backend_Development/VideoPageBKD5';
import VideoPageBKD6 from './learningPaltform/videopage/Backend_Development/VideoPageBKD6';
import VideoPageBKD7 from './learningPaltform/videopage/Backend_Development/VideoPageBKD7';
import VideoPageBKD8 from './learningPaltform/videopage/Backend_Development/VideoPageBKD8';
import VideoPageBKD9 from './learningPaltform/videopage/Backend_Development/VideoPageBKD9';
import VideoPageIOS1 from './learningPaltform/videopage/ios_Development/VideoPageIOS1';
import VideoPageIOS10 from './learningPaltform/videopage/ios_Development/VideoPageIOS10';
import VideoPageIOS11 from './learningPaltform/videopage/ios_Development/VideoPageIOS11.';
import VideoPageIOS2 from './learningPaltform/videopage/ios_Development/VideoPageIOS2';
import VideoPageIOS3 from './learningPaltform/videopage/ios_Development/VideoPageIOS3';
import VideoPageIOS4 from './learningPaltform/videopage/ios_Development/VideoPageIOS4';
import VideoPageIOS5 from './learningPaltform/videopage/ios_Development/VideoPageIOS5';
import VideoPageIOS6 from './learningPaltform/videopage/ios_Development/VideoPageIOS6';
import VideoPageIOS7 from './learningPaltform/videopage/ios_Development/VideoPageIOS7';
import VideoPageIOS8 from './learningPaltform/videopage/ios_Development/VideoPageIOS8';
import VideoPageIOS9 from './learningPaltform/videopage/ios_Development/VideoPageIOS9';
import VideoPageJD1 from './learningPaltform/videopage/Java_Development/VideoPageJD1';
import VideoPageJD10 from './learningPaltform/videopage/Java_Development/VideoPageJD10';
import VideoPageJD11 from './learningPaltform/videopage/Java_Development/VideoPageJD11';
import VideoPageJD2 from './learningPaltform/videopage/Java_Development/VideoPageJD2';
import VideoPageJD3 from './learningPaltform/videopage/Java_Development/VideoPageJD3';
import VideoPageJD4 from './learningPaltform/videopage/Java_Development/VideoPageJD4';
import VideoPageJD5 from './learningPaltform/videopage/Java_Development/VideoPageJD5';
import VideoPageJD6 from './learningPaltform/videopage/Java_Development/VideoPageJD6';
import VideoPageJD7 from './learningPaltform/videopage/Java_Development/VideoPageJD7';
import VideoPageJD8 from './learningPaltform/videopage/Java_Development/VideoPageJD8';
import VideoPageJD9 from './learningPaltform/videopage/Java_Development/VideoPageJD9';
import VideoPageJS1 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS1';
import VideoPageJS10 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS10';
import VideoPageJS11 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS11';
import VideoPageJS2 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS2';
import VideoPageJS3 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS3';
import VideoPageJS4 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS4';
import VideoPageJS5 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS5';
import VideoPageJS6 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS6';
import VideoPageJS7 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS7';
import VideoPageJS8 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS8';
import VideoPageJS9 from './learningPaltform/videopage/JavaScript_Development/VideoPageJS9';
import VideoPageMN1 from './learningPaltform/videopage/Mern_Development/VideoPageMN1';
import VideoPageMN10 from './learningPaltform/videopage/Mern_Development/VideoPageMN10';
import VideoPageMN11 from './learningPaltform/videopage/Mern_Development/VideoPageMN11';
import VideoPageMN2 from './learningPaltform/videopage/Mern_Development/VideoPageMN2';
import VideoPageMN3 from './learningPaltform/videopage/Mern_Development/VideoPageMN3';
import VideoPageMN4 from './learningPaltform/videopage/Mern_Development/VideoPageMN4';
import VideoPageMN5 from './learningPaltform/videopage/Mern_Development/VideoPageMN5';
import VideoPageMN6 from './learningPaltform/videopage/Mern_Development/VideoPageMN6';
import VideoPageMN7 from './learningPaltform/videopage/Mern_Development/VideoPageMN7';
import VideoPageMN8 from './learningPaltform/videopage/Mern_Development/VideoPageMN8';
import VideoPageMN9 from './learningPaltform/videopage/Mern_Development/VideoPageMN9';
import VideoPageRD1 from './learningPaltform/videopage/React_Development/VideoPageRD1';
import VideoPageRD10 from './learningPaltform/videopage/React_Development/VideoPageRD10';
import VideoPageRD11 from './learningPaltform/videopage/React_Development/VideoPageRD11';
import VideoPageRD2 from './learningPaltform/videopage/React_Development/VideoPageRD2';
import VideoPageRD3 from './learningPaltform/videopage/React_Development/VideoPageRD3';
import VideoPageRD4 from './learningPaltform/videopage/React_Development/VideoPageRD4';
import VideoPageRD5 from './learningPaltform/videopage/React_Development/VideoPageRD5';
import VideoPageRD6 from './learningPaltform/videopage/React_Development/VideoPageRD6';
import VideoPageRD7 from './learningPaltform/videopage/React_Development/VideoPageRD7';
import VideoPageRD8 from './learningPaltform/videopage/React_Development/VideoPageRD8';
import VideoPageRD9 from './learningPaltform/videopage/React_Development/VideoPageRD9';
import VideoPageUI1 from './learningPaltform/videopage/UI/UX/VideoPageUI1';
import VideoPageUI10 from './learningPaltform/videopage/UI/UX/VideoPageUI10';
import VideoPageUI11 from './learningPaltform/videopage/UI/UX/VideoPageUI11';
import VideoPageUI2 from './learningPaltform/videopage/UI/UX/VideoPageUI2';
import VideoPageUI3 from './learningPaltform/videopage/UI/UX/VideoPageUI3';
import VideoPageUI4 from './learningPaltform/videopage/UI/UX/VideoPageUI4';
import VideoPageUI5 from './learningPaltform/videopage/UI/UX/VideoPageUI5';
import VideoPageUI6 from './learningPaltform/videopage/UI/UX/VideoPageUI6';
import VideoPageUI7 from './learningPaltform/videopage/UI/UX/VideoPageUI7';
import VideoPageUI8 from './learningPaltform/videopage/UI/UX/VideoPageUI8';
import VideoPageUI9 from './learningPaltform/videopage/UI/UX/VideoPageUI9';
import VideoPageWB1 from './learningPaltform/videopage/web_Development/VideoPageWB1';
import VideoPageWB10 from './learningPaltform/videopage/web_Development/VideoPageWB10';
import VideoPageWB11 from './learningPaltform/videopage/web_Development/VideopageWB11';
import VideoPageWB2 from './learningPaltform/videopage/web_Development/VideoPageWB2';
import VideoPageWB3 from './learningPaltform/videopage/web_Development/VideoPageWB3';
import VideoPageWB4 from './learningPaltform/videopage/web_Development/VideoPageWB4';
import VideoPageWB5 from './learningPaltform/videopage/web_Development/VideopageWB5';
import VideoPageWB6 from './learningPaltform/videopage/web_Development/VideopageWB6';
import VideoPageWB7 from './learningPaltform/videopage/web_Development/VideoPageWB7';
import VideoPageWB8 from './learningPaltform/videopage/web_Development/VideoPageWB8';
import VideoPageWB9 from './learningPaltform/videopage/web_Development/VideoPageWB9';

function App() {
  return (
    
      <>
        <Routes>
          
          <Route path="/" element={<HomePage />} />
          <Route path="/ComponentPage" element={<ComponentPage />} />
          <Route path="/CoursePage" element={<CoursePage />} />
          <Route path="/Testimonial" element={<Testimonial />} />
          <Route path="/AboutUsPage" element={<AboutUsPage />} />
          <Route path="/ContectPage" element={<ContectPage />} />
          <Route path="/SignUpPage" element={<SignUpPage />} />
          
          <Route path="/VideoPageAND1" element={<VideoPageAND1 />} />
          <Route path="/VideoPageAND2" element={<VideoPageAND2 />} />
          <Route path="/VideoPageAND3" element={<VideoPageAND3 />} />
          <Route path="/VideoPageAND4" element={<VideoPageAND4 />} />
          <Route path="/VideoPageAND5" element={<VideoPageAND5 />} />
          <Route path="/VideoPageAND6" element={<VideoPageAND6 />} />
          <Route path="/VideoPageAND7" element={<VideoPageAND7 />} />
          <Route path="/VideoPageAND8" element={<VideoPageAND8 />} />
          <Route path="/VideoPageAND9" element={<VideoPageAND9 />} />
          <Route path="/VideoPageAND10" element={<VideoPageAND10 />} />
          <Route path="/VideoPageAND11" element={<VideoPageAND11 />} />

          <Route path="/VideoPageAGD1" element={<VideoPageAGD1 />} />
          <Route path="/VideoPageAGD2" element={<VideoPageAGD2 />} />
          <Route path="/VideoPageAGD3" element={<VideoPageAGD3 />} />
          <Route path="/VideoPageAGD4" element={<VideoPageAGD4 />} />
          <Route path="/VideoPageAGD5" element={<VideoPageAGD5 />} />
          <Route path="/VideoPageAGD6" element={<VideoPageAGD6 />} />
          <Route path="/VideoPageAGD7" element={<VideoPageAGD7 />} />
          <Route path="/VideoPageAGD8" element={<VideoPageAGD8 />} />
          <Route path="/VideoPageAGD9" element={<VideoPageAGD9 />} />
          <Route path="/VideoPageAGD10" element={<VideoPageAGD10 />} />
          <Route path="/VideoPageAGD11" element={<VideoPageAGD11 />} />

          <Route path="/VideoPageBKD1" element={<VideoPageBKD1 />} />
          <Route path="/VideoPageBKD2" element={<VideoPageBKD2 />} />
          <Route path="/VideoPageBKD3" element={<VideoPageBKD3 />} />
          <Route path="/VideoPageBKD4" element={<VideoPageBKD4 />} />
          <Route path="/VideoPageBKD5" element={<VideoPageBKD5 />} />
          <Route path="/VideoPageBKD6" element={<VideoPageBKD6 />} />
          <Route path="/VideoPageBKD7" element={<VideoPageBKD7 />} />
          <Route path="/VideoPageBKD8" element={<VideoPageBKD8 />} />
          <Route path="/VideoPageBKD9" element={<VideoPageBKD9 />} />
          <Route path="/VideoPageBKD10" element={<VideoPageBKD10 />} />
          <Route path="/VideoPageBKD11" element={<VideoPageBKD11 />} />

          <Route path="/VideoPageIOS1" element={<VideoPageIOS1 />} />
          <Route path="/VideoPageIOS2" element={<VideoPageIOS2 />} />
          <Route path="/VideoPageIOS3" element={<VideoPageIOS3 />} />
          <Route path="/VideoPageIOS4" element={<VideoPageIOS4 />} />
          <Route path="/VideoPageIOS5" element={<VideoPageIOS5 />} />
          <Route path="/VideoPageIOS6" element={<VideoPageIOS6 />} />
          <Route path="/VideoPageIOS7" element={<VideoPageIOS7 />} />
          <Route path="/VideoPageIOS8" element={<VideoPageIOS8 />} />
          <Route path="/VideoPageIOS9" element={<VideoPageIOS9 />} />
          <Route path="/VideoPageIOS10" element={<VideoPageIOS10 />} />
          <Route path="/VideoPageIOS11" element={<VideoPageIOS11 />} />

          <Route path="/VideoPageJD1" element={<VideoPageJD1 />} />
          <Route path="/VideoPageJD2" element={<VideoPageJD2 />} />
          <Route path="/VideoPageJD3" element={<VideoPageJD3 />} />
          <Route path="/VideoPageJD4" element={<VideoPageJD4 />} />
          <Route path="/VideoPageJD5" element={<VideoPageJD5 />} />
          <Route path="/VideoPageJD6" element={<VideoPageJD6 />} />
          <Route path="/VideoPageJD7" element={<VideoPageJD7 />} />
          <Route path="/VideoPageJD8" element={<VideoPageJD8 />} />
          <Route path="/VideoPageJD9" element={<VideoPageJD9 />} />
          <Route path="/VideoPageJD10" element={<VideoPageJD10 />} />
          <Route path="/VideoPageJD11" element={<VideoPageJD11 />} />

          <Route path="/VideoPageJS1" element={<VideoPageJS1 />} />
          <Route path="/VideoPageJS2" element={<VideoPageJS2 />} />
          <Route path="/VideoPageJS3" element={<VideoPageJS3 />} />
          <Route path="/VideoPageJS4" element={<VideoPageJS4 />} />
          <Route path="/VideoPageJS5" element={<VideoPageJS5 />} />
          <Route path="/VideoPageJS6" element={<VideoPageJS6 />} />
          <Route path="/VideoPageJS7" element={<VideoPageJS7 />} />
          <Route path="/VideoPageJS8" element={<VideoPageJS8 />} />
          <Route path="/VideoPageJS9" element={<VideoPageJS9 />} />
          <Route path="/VideoPageJS10" element={<VideoPageJS10 />} />
          <Route path="/VideoPageJS11" element={<VideoPageJS11 />} />

          <Route path="/VideoPageRD1" element={<VideoPageRD1 />} />
          <Route path="/VideoPageRD2" element={<VideoPageRD2 />} />
          <Route path="/VideoPageRD3" element={<VideoPageRD3 />} />
          <Route path="/VideoPageRD4" element={<VideoPageRD4 />} />
          <Route path="/VideoPageRD5" element={<VideoPageRD5 />} />
          <Route path="/VideoPageRD6" element={<VideoPageRD6 />} />
          <Route path="/VideoPageRD7" element={<VideoPageRD7 />} />
          <Route path="/VideoPageRD8" element={<VideoPageRD8 />} />
          <Route path="/VideoPageRD9" element={<VideoPageRD9 />} />
          <Route path="/VideoPageRD10" element={<VideoPageRD10 />} />
          <Route path="/VideoPageRD11" element={<VideoPageRD11 />} />

          <Route path="/VideoPageMN1" element={<VideoPageMN1 />} />
          <Route path="/VideoPageMN2" element={<VideoPageMN2 />} />
          <Route path="/VideoPageMN3" element={<VideoPageMN3 />} />
          <Route path="/VideoPageMN4" element={<VideoPageMN4 />} />
          <Route path="/VideoPageMN5" element={<VideoPageMN5 />} />
          <Route path="/VideoPageMN6" element={<VideoPageMN6 />} />
          <Route path="/VideoPageMN7" element={<VideoPageMN7 />} />
          <Route path="/VideoPageMN8" element={<VideoPageMN8 />} />
          <Route path="/VideoPageMN9" element={<VideoPageMN9 />} />
          <Route path="/VideoPageMN10" element={<VideoPageMN10 />} />
          <Route path="/VideoPageMN11" element={<VideoPageMN11 />} />

          <Route path="/VideoPageWB1" element={<VideoPageWB1 />} />
          <Route path="/VideoPageWB2" element={<VideoPageWB2 />} />
          <Route path="/VideoPageWB3" element={<VideoPageWB3 />} />
          <Route path="/VideoPageWB4" element={<VideoPageWB4 />} />
          <Route path="/VideoPageWB5" element={<VideoPageWB5 />} />
          <Route path="/VideoPageWB6" element={<VideoPageWB6 />} />
          <Route path="/VideoPageWB7" element={<VideoPageWB7 />} />
          <Route path="/VideoPageWB8" element={<VideoPageWB8 />} />
          <Route path="/VideoPageWB9" element={<VideoPageWB9 />} />
          <Route path="/VideoPageWB10" element={<VideoPageWB10 />} />
          <Route path="/VideoPageWB11" element={<VideoPageWB11 />} />

          <Route path="/VideoPageUI1" element={<VideoPageUI1 />} />
          <Route path="/VideoPageUI2" element={<VideoPageUI2 />} />
          <Route path="/VideoPageUI3" element={<VideoPageUI3 />} />
          <Route path="/VideoPageUI4" element={<VideoPageUI4 />} />
          <Route path="/VideoPageUI5" element={<VideoPageUI5 />} />
          <Route path="/VideoPageUI6" element={<VideoPageUI6 />} />
          <Route path="/VideoPageUI7" element={<VideoPageUI7 />} />
          <Route path="/VideoPageUI8" element={<VideoPageUI8 />} />
          <Route path="/VideoPageUI9" element={<VideoPageUI9 />} />
          <Route path="/VideoPageUI10" element={<VideoPageUI10 />} />
          <Route path="/VideoPageUI11" element={<VideoPageUI11 />} />

          <Route path="/FooterPage" element={<FooterPage />} />
        </Routes>
      </>
    
  );
}

export default App;








// import React from 'react';
// import { Route, Routes } from 'react-router-dom';
// import AdminPage from './dashboard/AdminPage';
// import DashboardPage from './dashboard/DashboardPage';
// import HomePage from './dashboard/HomePage';
// import JobDashboard from './dashboard/JobDashboard';
// import MenuPage from './dashboard/MenuPage';

// function App() {
//   return (
    
//       <>
//         <Routes>
//           <Route path="/JobDashboard" element={<JobDashboard />} />
//           <Route path="/" element={<HomePage />} />
//           <Route path="/menu" element={<MenuPage />} />
//           <Route path="/dashboard" element={<DashboardPage />} />
//           <Route path="/admin" element={<AdminPage />} />
//         </Routes>
//       </>
    
//   );
// }

// export default App;
















// import { Route, Routes } from 'react-router-dom';
// import './App.css';
// import Container from './continer/Continer';
// import AdminPage from './dashboard/AdminPage';
// import Dashboard from './dashboard/Dashboard';
// import DashboardPage from './dashboard/DashboardPage';
// import HomePage from './dashboard/HomePage';
// import JobDashboard from './dashboard/JobDashboard';
// import MenuPage from './dashboard/MenuPage';
// import Industries from './industries/Industries';
// import News from './news/News';
// function App() {
//   return (
//       <>
//         <Routes>
//           <Route path="/Container" element={<Container />} />
//           <Route path="/Industries" element={<Industries />} />
//           <Route path="/News" element={<News />} />
//           <Route path="/" element={<JobDashboard />} />
//           <Route path="/Dashboard" element={<Dashboard />} />
//           <Route path="/DashboardPage" element={<DashboardPage />} />
//           <Route path="/Admin" element={<AdminPage />} />
//           <Route path="/HomePage" element={<HomePage />} />
//           <Route path="/MenuPage" element={<MenuPage />} />
//         </Routes>
//       </>
    
//   );
// }

// export default App;
