import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=O5IXf8qB9U4&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_',
            'https://www.youtube.com/watch?v=FlwYtS4mIQw&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=2',
            'https://www.youtube.com/watch?v=SKvsPh0qdQU&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=3',
            'https://www.youtube.com/watch?v=h9r_UpOzajA&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=4',
            'https://www.youtube.com/watch?v=yhqEqcWMoqs&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=5',
            'https://www.youtube.com/watch?v=C5h1ZE1AhlI&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=6',
            'https://www.youtube.com/watch?v=gJ6cvzZ0ewQ&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=7',
            'https://www.youtube.com/watch?v=FdJz-rfMPFk&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=8',
            'https://www.youtube.com/watch?v=O9-t0DtoobA&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=9',
            'https://www.youtube.com/watch?v=pYQBvAYnL1I&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=10',
            'https://www.youtube.com/watch?v=znXYS0egkqw&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=11',
            'https://www.youtube.com/watch?v=l-gMLFgJ1Ww&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=12',
            'https://www.youtube.com/watch?v=-_lK35Wczfc&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=13',
            'https://www.youtube.com/watch?v=3PkwYR1xuGk&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=14',
            'https://www.youtube.com/watch?v=sG1n8umTJnI&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=15',
            'https://www.youtube.com/watch?v=vSCvYv3ybgE&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=16',
            'https://www.youtube.com/watch?v=4SR5b2bEahM&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=17',
            'https://www.youtube.com/watch?v=FbfL1BOBeG4&list=PLdvOfoe7PXT0ouChAnR1nHlT8BJIo5hP_&index=18',
        ]
    },
    
};

const VideoPageAND3 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageAND3;
