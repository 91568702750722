import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=Iskzda0zpuU&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=1',
            'https://www.youtube.com/watch?v=yGPIydkNz0s&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=2',
            'https://www.youtube.com/watch?v=lITsZKidY1s&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=3',
            'https://www.youtube.com/watch?v=Xq1zPeEXvQw&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=4',
            'https://www.youtube.com/watch?v=zIKqVxABNVM&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=5',
            'https://www.youtube.com/watch?v=t60YeZxEVDY&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=6',
            'https://www.youtube.com/watch?v=9hesE4O8Was&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=7',
            'https://www.youtube.com/watch?v=AsW84fA4Nb8&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=8',
            'https://www.youtube.com/watch?v=WhfeDv7UKXo&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=9',
            'https://www.youtube.com/watch?v=2eiVPH3GLtY&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=10',
            'https://www.youtube.com/watch?v=CKYVaX_DnyM&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=11',
            'https://www.youtube.com/watch?v=rCqkxUAuhtg&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=12',
            'https://www.youtube.com/watch?v=DYc9vwNYkX8&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=13',
            'https://www.youtube.com/watch?v=LCpWswTCFUQ&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=14',
            'https://www.youtube.com/watch?v=uJP9XTL0GSc&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=15',
            'https://www.youtube.com/watch?v=htuJ9qaeMJA&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=16',
            'https://www.youtube.com/watch?v=G0PUSWmpTwI&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=17',
            'https://www.youtube.com/watch?v=J3Q-QQfUkOY&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=18',
            'https://www.youtube.com/watch?v=lkceQupdFOE&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=19',
            'https://www.youtube.com/watch?v=3DeddDGmtv0&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=20',
            'https://www.youtube.com/watch?v=mlpGp-Lo2-Y&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=21',
            'https://www.youtube.com/watch?v=S6xSDI43pfY&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=22',
            'https://www.youtube.com/watch?v=VmeNMj4jwro&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=23',
            'https://www.youtube.com/watch?v=0m57t0Dl6zw&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=24',
            'https://www.youtube.com/watch?v=JVawZmQBaP4&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=25',
            'https://www.youtube.com/watch?v=qfIlZmGw3Mc&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=26',
            'https://www.youtube.com/watch?v=9P5NovhiUz4&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=27',
            'https://www.youtube.com/watch?v=7azau3JVZ1U&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=28',
            'https://www.youtube.com/watch?v=dDSdFcZ1bKs&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=29',
            'https://www.youtube.com/watch?v=Bi0n7UgMuoc&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=30',
            'https://www.youtube.com/watch?v=Gos62oCsesA&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=31',
            'https://www.youtube.com/watch?v=-kXHlXLmr2s&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=32',
            'https://www.youtube.com/watch?v=HVwB0WxGr_4&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=33',
            'https://www.youtube.com/watch?v=xaha-r8L4XA&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=34',
            'https://www.youtube.com/watch?v=rKm5vTVwLls&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=35',
            'https://www.youtube.com/watch?v=ElxkWWBKck4&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=36',
            'https://www.youtube.com/watch?v=kCUptPemDYY&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=37',
            'https://www.youtube.com/watch?v=GUbDMA71sac&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=38',
            'https://www.youtube.com/watch?v=BII2-EI9lDM&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=39',
            'https://www.youtube.com/watch?v=ofV2kYz-f-Y&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=40',
            'https://www.youtube.com/watch?v=i16LhFeY7II&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=41',
            'https://www.youtube.com/watch?v=alQ7aCNWVO4&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=42',
            'https://www.youtube.com/watch?v=66B2b11yJ4I&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=43',
            'https://www.youtube.com/watch?v=8IxrqeNf5to&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=44',
            'https://www.youtube.com/watch?v=6xmrko16Ywo&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=45',
            'https://www.youtube.com/watch?v=puHNuMZOPOI&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=46',
            'https://www.youtube.com/watch?v=VZTNsHkN2GE&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=47',
            'https://www.youtube.com/watch?v=na_4tGCB-Xo&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=48',
            'https://www.youtube.com/watch?v=F6SE_5h7InY&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=49',
            'https://www.youtube.com/watch?v=o8bEJ60lWns&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=50',
            'https://www.youtube.com/watch?v=go4c9seTlY8&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=51',
            'https://www.youtube.com/watch?v=36ATxsCHdrg&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=52',
            'https://www.youtube.com/watch?v=7gc7YqtwCdc&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=53',
            'https://www.youtube.com/watch?v=_mArYbqBWOE&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=54',
            'https://www.youtube.com/watch?v=v_Ol85_zMxg&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=55',
            'https://www.youtube.com/watch?v=-brASIG8HQM&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=56',
            'https://www.youtube.com/watch?v=omlGa93Ui2o&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=57',
            'https://www.youtube.com/watch?v=XG-MzzWFwEc&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=58',
            'https://www.youtube.com/watch?v=4VhtnpIsass&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=59',
            'https://www.youtube.com/watch?v=RF22vm2IoCs&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=60',
            'https://www.youtube.com/watch?v=hs9WzN5NNxo&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=61',
            'https://www.youtube.com/watch?v=A7lYfo9H_6E&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=62',
            'https://www.youtube.com/watch?v=X6lgoGOSyQY&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=63',
            'https://www.youtube.com/watch?v=ALQquxG--dU&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=64',
            'https://www.youtube.com/watch?v=3uI36meKwvs&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=65',
            'https://www.youtube.com/watch?v=tCJ0RqVzn5c&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=66',
            'https://www.youtube.com/watch?v=D_zAtxi_-p0&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=67',
            'https://www.youtube.com/watch?v=nTPhQPtAPqU&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=68',
            'https://www.youtube.com/watch?v=KZM0mLNhSdg&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=69',
            'https://www.youtube.com/watch?v=rcxz9otHiak&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=70',
            'https://www.youtube.com/watch?v=KipCKAuQ5Fw&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=71',
            'https://www.youtube.com/watch?v=QOhN6wBV1vE&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=72',
            'https://www.youtube.com/watch?v=-PiTweZF6uw&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=73',
            'https://www.youtube.com/watch?v=SeoB5BeyAc4&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=74',
            'https://www.youtube.com/watch?v=KOBi7MVLdag&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=75',
            'https://www.youtube.com/watch?v=biLrWCCAIqw&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=76',
            'https://www.youtube.com/watch?v=yFid5nxaTDE&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=77',
            'https://www.youtube.com/watch?v=aiN_ctvmGOg&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=78',
            'https://www.youtube.com/watch?v=cNOSwaTtBW0&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=79',
            'https://www.youtube.com/watch?v=nKK-a8vfNm4&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=80',
            'https://www.youtube.com/watch?v=F6MCpFTUjyc&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=81',
            'https://www.youtube.com/watch?v=VHUxSO8fzLQ&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=82',
            'https://www.youtube.com/watch?v=vQ3_eEriTRw&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=83',
            'https://www.youtube.com/watch?v=_Glm3Zdrdhg&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=84',
            'https://www.youtube.com/watch?v=IzchlSjW7tA&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=85',
            'https://www.youtube.com/watch?v=9SN47a5ztIs&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=86',
            'https://www.youtube.com/watch?v=3CXrUA6VWeg&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=87',
            'https://www.youtube.com/watch?v=0YsQT2Tw0SE&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=88',
            'https://www.youtube.com/watch?v=f4O796dgdW0&list=PL8p2I9GklV466xs0qGUvLGAXy6xLyEcQy&index=89',
        ]
    },
    'React.js Fundamentals': {
        title: 'Introduction to React.js Fundamentals',
        videoList: [
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
        ]
    },
    'Node.js & Express': {
        title: 'Introduction to Node.js & Express',
        videoList: [
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
        ]
    },
};

const VideoPageJD1 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageJD1;