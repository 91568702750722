import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=HyU4vkZ2NB8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=1',
            'https://www.youtube.com/watch?v=mbmka5RYuXg&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=2',
            'https://www.youtube.com/watch?v=y1R_3bly7MQ&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=3',
            'https://www.youtube.com/watch?v=YW6kyOUpWQo&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=4',
            'https://www.youtube.com/watch?v=PmiSj2QGZVM&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=5',
            'https://www.youtube.com/watch?v=-NpB2E0cXcY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=6',
            'https://www.youtube.com/watch?v=xMCAkUsdDT8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=7',
            'https://www.youtube.com/watch?v=CqwbpwEK9OU&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=8',
            'https://www.youtube.com/watch?v=hvlhxEtH5IY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=9',
            'https://www.youtube.com/watch?v=dKJbRxKBats&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=10',
            'https://www.youtube.com/watch?v=poDgvBrEZGo&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=11',
            'https://www.youtube.com/watch?v=-nZ0NAGIdaI&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=12',
            'https://www.youtube.com/watch?v=ORMrUK9TRec&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=13',
            'https://www.youtube.com/watch?v=IE7fAJD4GhY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=14',
            'https://www.youtube.com/watch?v=L6xPOSoKgvM&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=15',
            'https://www.youtube.com/watch?v=DgiABhh9JYw&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=16',
            'https://www.youtube.com/watch?v=UtaY0JgO1pE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=17',
            'https://www.youtube.com/watch?v=PeCOKgAua7A&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=18',
            'https://www.youtube.com/watch?v=0TtJwa5vL6Y&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=19',
            'https://www.youtube.com/watch?v=MYjEsHQth5s&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=20',
            'https://www.youtube.com/watch?v=6oHH8de8RSY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=21',
            'https://www.youtube.com/watch?v=fXaytserkTQ&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=22',
            'https://www.youtube.com/watch?v=LCOxwjh1Go8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=23',
            'https://www.youtube.com/watch?v=8dpo_nCBR0Q&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=24',
            'https://www.youtube.com/watch?v=XfNjYV-h6hw&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=25',
            'https://www.youtube.com/watch?v=BnrxijwJJ_8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=26',
            'https://www.youtube.com/watch?v=POmGgYhtNn8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=27',
            'https://www.youtube.com/watch?v=mMLroKvgaeA&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=28',
            'https://www.youtube.com/watch?v=c_vy_UqO9pA&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=29',
            'https://www.youtube.com/watch?v=ec-dTWBKN1E&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=30',
            'https://www.youtube.com/watch?v=RNg4pjN3Fc0&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=31',
            'https://www.youtube.com/watch?v=NiqDfdVhhPM&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=32',
            'https://www.youtube.com/watch?v=kVd9cLfRACg&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=33',
            'https://www.youtube.com/watch?v=a5_0-a6NQ50&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=34',
            'https://www.youtube.com/watch?v=ZEGbgi9k2Fg&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=35',
            'https://www.youtube.com/watch?v=vQPC-3IH7uQ&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=36',
            'https://www.youtube.com/watch?v=DhvaTtN7j-8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=37',
            'https://www.youtube.com/watch?v=p5vjHet3Mqw&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=38',
            'https://www.youtube.com/watch?v=sWWC2TpXrY0&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=39',
            'https://www.youtube.com/watch?v=33FjFO1AzYk&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=40',
            'https://www.youtube.com/watch?v=nNAhZvL3bV4&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=41',
            'https://www.youtube.com/watch?v=ygUb950FQA4&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=42',
            'https://www.youtube.com/watch?v=DJ9niKqYtUc&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=43',
            'https://www.youtube.com/watch?v=W2Xn42Id2V4&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=44',
            'https://www.youtube.com/watch?v=kp-oGImpO_c&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=45',
            'https://www.youtube.com/watch?v=WjOUheWLpTs&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=46',
            'https://www.youtube.com/watch?v=eVUKEItFt8c&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=47',
            'https://www.youtube.com/watch?v=VeFPqmkXAGE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=48',
            'https://www.youtube.com/watch?v=hn4gD6dys50&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=49',
            'https://www.youtube.com/watch?v=I2bEcv_3iMU&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=50',
            'https://www.youtube.com/watch?v=Brp4Ad-2uqA&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=51',
            'https://www.youtube.com/watch?v=ccpm3X74LcY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=52',
            'https://www.youtube.com/watch?v=n08uI7gViLE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=53',
            'https://www.youtube.com/watch?v=DeyFygSs9Qc&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=54',
            'https://www.youtube.com/watch?v=HOebDG1XQMA&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=55',
            'https://www.youtube.com/watch?v=uu6o0Qvp2gg&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=56',
            'https://www.youtube.com/watch?v=FEqF1_jDV-A&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=57',
            'https://www.youtube.com/watch?v=zK3jXRNdp2M&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=58',
            'https://www.youtube.com/watch?v=w8inTdDZDfw&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=59',
            'https://www.youtube.com/watch?v=o35gogTi8lY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=60',
            'https://www.youtube.com/watch?v=LipvIFwWRaI&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=61',
            'https://www.youtube.com/watch?v=HMxANAdlAtU&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=62',
            'https://www.youtube.com/watch?v=nXwP5ic1vtQ&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=63',
            'https://www.youtube.com/watch?v=-cCG3BAFzUU&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=64',
            'https://www.youtube.com/watch?v=ism9JAmNBYY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=65',
            'https://www.youtube.com/watch?v=UKlGLvKDia8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=66',
            'https://www.youtube.com/watch?v=Pen_en0zhIY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=67',
            'https://www.youtube.com/watch?v=LNrAh0-D4HA&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=68',
            'https://www.youtube.com/watch?v=r-JNSsrIBds&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=69',
            'https://www.youtube.com/watch?v=7jcXW0Xwd4Y&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=70',
            'https://www.youtube.com/watch?v=MvlFAgAxC8s&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=71',
            'https://www.youtube.com/watch?v=KJhYqe04bGo&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=72',
            'https://www.youtube.com/watch?v=D5Ha9S5SVsw&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=73',
            'https://www.youtube.com/watch?v=_3dnTaq9KdE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=74',
            'https://www.youtube.com/watch?v=pBUyd6QlSDU&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=75',
            'https://www.youtube.com/watch?v=BO1utHYhsms&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=76',
            'https://www.youtube.com/watch?v=xg6r4AnPZzY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=77',
            'https://www.youtube.com/watch?v=InTLAZ99Y40&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=78',
            'https://www.youtube.com/watch?v=q2kYT-Sd-XQ&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=79',
            'https://www.youtube.com/watch?v=9-9sdoaGyUM&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=80',
            'https://www.youtube.com/watch?v=ENcJWl9o4ag&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=81',
            'https://www.youtube.com/watch?v=fB3oQFW--qg&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=82',
            'https://www.youtube.com/watch?v=pHKuSMZc2kk&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=83',
            'https://www.youtube.com/watch?v=JlmIf8mo-O8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=84',
            'https://www.youtube.com/watch?v=4-dpxp0yrvo&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=85',
            'https://www.youtube.com/watch?v=Ip0LaPUcxiU&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=86',
            'https://www.youtube.com/watch?v=HQo4Nr2d3DA&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=87',
            'https://www.youtube.com/watch?v=7NJavvSLYx8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=88',
            'https://www.youtube.com/watch?v=SoqujjHpO8Q&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=89',
            'https://www.youtube.com/watch?v=rK4walNCMzI&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=90',
            'https://www.youtube.com/watch?v=ZDWg9qFwIIY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=91',
            'https://www.youtube.com/watch?v=lqoVrGWFrJI&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=92',
            'https://www.youtube.com/watch?v=cZRBPGyLw9E&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=93',
            'https://www.youtube.com/watch?v=cwKyXZjuvRY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=94',
            'https://www.youtube.com/watch?v=kIgpSokJgzY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=95',
            'https://www.youtube.com/watch?v=bLi1qr6h4T4&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=96',
            'https://www.youtube.com/watch?v=YDraUaHz55o&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=97',
            'https://www.youtube.com/watch?v=ewdSmjMbsP0&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=98',
            'https://www.youtube.com/watch?v=H0dxrwRT1aE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=99',
            'https://www.youtube.com/watch?v=gVszXHio7hU&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=100',
            'https://www.youtube.com/watch?v=SvOKrWf7cmc&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=101',
            'https://www.youtube.com/watch?v=R-8k2GYK1G4&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=102',
            'https://www.youtube.com/watch?v=hMMeFhEenM8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=103',
            'https://www.youtube.com/watch?v=oRmgM9xQLDE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=104',
            'https://www.youtube.com/watch?v=diaPAI0n-tw&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=105',
            'https://www.youtube.com/watch?v=kG8rt_wvqKo&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=106',
            'https://www.youtube.com/watch?v=GFRKvB08FBg&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=107',
            'https://www.youtube.com/watch?v=oalCpTO1oA4&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=108',
            'https://www.youtube.com/watch?v=yJUf6scL3WE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=109',
            'https://www.youtube.com/watch?v=oR1lc8jLGpY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=110',
            'https://www.youtube.com/watch?v=A9yr9mFz8oE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=111',
            'https://www.youtube.com/watch?v=GHK9WuhqFFE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=112',
            'https://www.youtube.com/watch?v=LwYvxuBku44&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=113',
            'https://www.youtube.com/watch?v=mOhBUWJ9HSM&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=114',
            'https://www.youtube.com/watch?v=CFsiJt0ka-8&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=115',
            'https://www.youtube.com/watch?v=WW2lUpQgW88&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=116',
            'https://www.youtube.com/watch?v=Q9JIjs40ujI&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=117',
            'https://www.youtube.com/watch?v=-dMSKqp9YT4&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=118',
            'https://www.youtube.com/watch?v=kiHu0-Z6xHI&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=119',
            'https://www.youtube.com/watch?v=gWmZ2UxFg7s&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=120',
            'https://www.youtube.com/watch?v=T3K5FMQ6OEM&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=121',
            'https://www.youtube.com/watch?v=GanrZ3xxPps&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=122',
            'https://www.youtube.com/watch?v=vx4BtrqlKCY&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=123',
            'https://www.youtube.com/watch?v=4B85lFGTL-U&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=124',
            'https://www.youtube.com/watch?v=crJaLpCiNfI&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=125',
            'https://www.youtube.com/watch?v=Nmof6xhlDlE&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=126',
            'https://www.youtube.com/watch?v=ap7lZBqD9uI&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=127',
            'https://www.youtube.com/watch?v=g9rVra5wG7s&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=128',
            'https://www.youtube.com/watch?v=0UR0LYYgXqc&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=129',
            'https://www.youtube.com/watch?v=6AujjRltNWM&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=130',
            'https://www.youtube.com/watch?v=92sKMUmv_wo&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=131',
            'https://www.youtube.com/watch?v=Ormjb-BX1sw&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=132',
            'https://www.youtube.com/watch?v=J_aF7uJDJ9o&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=133',
            'https://www.youtube.com/watch?v=-5LGc3S0S1A&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=134',
            'https://www.youtube.com/watch?v=l93dUjCBWeg&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=135',
            'https://www.youtube.com/watch?v=nN5_L-CvxIs&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=136',
            'https://www.youtube.com/watch?v=O-6WX-NLf9U&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=137',
            'https://www.youtube.com/watch?v=-VZ-DZyOfv0&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=138',
            'https://www.youtube.com/watch?v=q-toXXOIduM&list=PLjVLYmrlmjGdDps6HAwOOVoAtBPAgIOXL&index=139',
        ]
    },
    
};

const VideoPageAND1 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageAND1;
