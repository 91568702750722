import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=EH3vGeqeIAo&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=1',
            'https://www.youtube.com/watch?v=pOV4EjUtl70&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=2',
            'https://www.youtube.com/watch?v=fFHyqhmnVfs&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=3',
            'https://www.youtube.com/watch?v=10hRlpUNeNA&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=4',
            'https://www.youtube.com/watch?v=VbGl3msgce8&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=5',
            'https://www.youtube.com/watch?v=lA_mNpddN5U&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=6',
            'https://www.youtube.com/watch?v=9B4CvtzXRpc&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=7',
            'https://www.youtube.com/watch?v=w4z8Py-UoNk&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=8',
            'https://www.youtube.com/watch?v=S5EpsMjel-M&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=9',
            'https://www.youtube.com/watch?v=eWnZVUXMq8k&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=10',
            'https://www.youtube.com/watch?v=6KPXn2Ha0cM&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=11',
            'https://www.youtube.com/watch?v=qgZiUvV41TI&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=12',
            'https://www.youtube.com/watch?v=HqcGLJSORaA&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=13',
            'https://www.youtube.com/watch?v=VKXnSwNm_lE&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=14',
            'https://www.youtube.com/watch?v=_u-WgSN5ymU&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=15',
            'https://www.youtube.com/watch?v=7DVpag3cO0g&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=16',
            'https://www.youtube.com/watch?v=L2_gIrDxCes&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=17',
            'https://www.youtube.com/watch?v=9azRerL6CZc&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=18',
            'https://www.youtube.com/watch?v=4_Ge2QEcT8k&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=19',
            'https://www.youtube.com/watch?v=fDTf1mk-jQg&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=20',
            'https://www.youtube.com/watch?v=qNnR7cuVliI&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=21',
            'https://www.youtube.com/watch?v=VUgl3i8DdW4&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=22',
            'https://www.youtube.com/watch?v=-5yWyE4AiVk&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=23',
            'https://www.youtube.com/watch?v=3ouqT3lfiUE&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=24',
            'https://www.youtube.com/watch?v=Uc3Iq41npyI&list=PLu71SKxNbfoBGh_8p_NS-ZAh6v7HhYqHW&index=25',
        ]
    },
    
};

const VideoPageBKD4 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageBKD4;