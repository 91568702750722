import React from 'react';
import girlImage from "../../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=3aOU9MbITlM&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ',
            'https://www.youtube.com/watch?v=pvD1l2BmfrE&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=2',
            'https://www.youtube.com/watch?v=7KLvP3r2eGU&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=3',
            'https://www.youtube.com/watch?v=vMAnBpD7D-w&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=4',
            'https://www.youtube.com/watch?v=25zB8JIr71Y&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=5',
            'https://www.youtube.com/watch?v=8i8jDIV5QsE&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=6',
            'https://www.youtube.com/watch?v=pqKGe7L5QZQ&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=7',
            'https://www.youtube.com/watch?v=tYkXUVdNKhw&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=8',
            'https://www.youtube.com/watch?v=iBSq1mwNxC4&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=9',
            'https://www.youtube.com/watch?v=oShGsQyHxmM&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=10',
            'https://www.youtube.com/watch?v=A-7nWG5n8oM&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=11',
            'https://www.youtube.com/watch?v=ZXCP_s4rmPs&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=12',
            'https://www.youtube.com/watch?v=XMVmP7ECHu0&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=13',
            'https://www.youtube.com/watch?v=S-y1902UD-M&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=14',
            'https://www.youtube.com/watch?v=W5lc8AFhuM0&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=15',
            'https://www.youtube.com/watch?v=WLFFuNSJ1Ng&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=16',
            'https://www.youtube.com/watch?v=RFdtXi4Jr7o&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=17',
            'https://www.youtube.com/watch?v=GQJAOTsY_IM&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=18',
            'https://www.youtube.com/watch?v=U7cI9M7Qelg&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=19',
            'https://www.youtube.com/watch?v=1X2rvHKoqH4&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=20',
            'https://www.youtube.com/watch?v=Gi-q2AQmp54&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=21',
            'https://www.youtube.com/watch?v=CGSGuqBPsOs&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=22',
            'https://www.youtube.com/watch?v=1EFIDMaIQ60&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=23',
            'https://www.youtube.com/watch?v=CVWDLEGQUN0&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=24',
            'https://www.youtube.com/watch?v=wQibFSsgXt8&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=25',
            'https://www.youtube.com/watch?v=VK7EI7PivAs&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=26',
            'https://www.youtube.com/watch?v=VcHxCtTapoI&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=27',
            'https://www.youtube.com/watch?v=mkVmVyUNncw&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=28',
            'https://www.youtube.com/watch?v=4PY0t77djWs&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=29',
            'https://www.youtube.com/watch?v=XSeYALyisbQ&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=30',
            'https://www.youtube.com/watch?v=CuoA6vvZVPA&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=31',
            'https://www.youtube.com/watch?v=SYWZhT8n_5w&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=32',
            'https://www.youtube.com/watch?v=Ne-ckqCUIqw&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=33',
            'https://www.youtube.com/watch?v=GDTrIVJPUeQ&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=34',
            'https://www.youtube.com/watch?v=XT4-JhrVl2Q&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=35',
            'https://www.youtube.com/watch?v=rCNOy3IFaW0&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=36',
            'https://www.youtube.com/watch?v=8tYyEhaaiLc&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=37',
            'https://www.youtube.com/watch?v=6LlbgykhVZo&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=38',
            'https://www.youtube.com/watch?v=sVJ440A2MDc&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=39',
            'https://www.youtube.com/watch?v=hBTVPshgucA&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=40',
            'https://www.youtube.com/watch?v=WhSc-oEkNbI&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=41',
            'https://www.youtube.com/watch?v=ps3Gt1qF7JE&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=42',
            'https://www.youtube.com/watch?v=w2GV_kE69to&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=43',
            'https://www.youtube.com/watch?v=M5oXlV_w2OQ&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=44',
            'https://www.youtube.com/watch?v=M5oXlV_w2OQ&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=45',
            'https://www.youtube.com/watch?v=1OPPvooQtSc&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=46',
            'https://www.youtube.com/watch?v=6JLRLVPmrtY&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=47',
            'https://www.youtube.com/watch?v=uAxTSwfij8s&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=48',
            'https://www.youtube.com/watch?v=LJLnc_hlGPk&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=49',
            'https://www.youtube.com/watch?v=NLMu6hoHfYE&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=50',
            'https://www.youtube.com/watch?v=HButCubDmZM&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=51',
            'https://www.youtube.com/watch?v=JbZAWsW6rgw&list=PLjwm_8O3suyPkIphVrJaebR2qE8jylWBQ&index=52',
        ]
    },
    
};

const VideoPageUI10 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>UI/UX</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp  (Prectices)</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageUI10;