import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=1ukSR1GRtMU&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ',
            'https://www.youtube.com/watch?v=bKueYVtV0eA&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=2',
            'https://www.youtube.com/watch?v=LtlsX_lCfK4&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=3',
            'https://www.youtube.com/watch?v=FLQ-Vhw1NYQ&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=4',
            'https://www.youtube.com/watch?v=TSIhiZ5jRB0&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=5',
            'https://youtube.com/watch?v=C5lpPjoivaw&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=6',
            'https://youtube.com/watch?v=km2P_KQJyO0&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=7',
            'https://www.youtube.com/watch?v=zwPBMg3SHVU&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=8',
            'https://www.youtube.com/watch?v=Hxh6nNHSUjo&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=9',
            'https://www.youtube.com/watch?v=ABmqtI7ec7E&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=10',
            'https://www.youtube.com/watch?v=H0cJ0gUlgE8&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=11',
            'https://www.youtube.com/watch?v=a6oKFvGuTH4&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=12',
            'https://www.youtube.com/watch?v=siFU8c_Heu0&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=13',
            'https://www.youtube.com/watch?v=RdPkFd6_fTA&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=14',
            'https://www.youtube.com/watch?v=zNZvuP8h1vs&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=15',
            'https://youtube.com/watch?v=c063ddhWafo&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=16',
            'https://youtube.com/watch?v=p5dkB3Mrxdo&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=17',
            'https://www.youtube.com/watch?v=_AC90CiDoKA&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=18',
            'https://www.youtube.com/watch?v=QgQJJ0y0A6w&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=19',
            'https://www.youtube.com/watch?v=XIxahpXU_QE&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=20',
            'https://youtube.com/watch?v=jAxNZYX7mHM&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=21',
            'https://www.youtube.com/watch?v=aqePcMyeoIY&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=22',
            'https://www.youtube.com/watch?v=WghpP9W2vXo&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=23',
            'https://www.youtube.com/watch?v=WG5tJIAq5b0&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=24',
            'https://www.youtube.com/watch?v=pDzQGolJayE&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=25',
            'https://www.youtube.com/watch?v=nHsxIQ9KMn0&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=26',
            'https://www.youtube.com/watch?v=WdXcJdhWcEY&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=27',
            'https://www.youtube.com/watch?v=AqsmaT1U6sQ&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=28',
            'https://www.youtube.com/watch?v=9lCQhwo8WT4&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=29',
            'https://www.youtube.com/watch?v=HemchBJQdgM&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=30',
            'https://www.youtube.com/watch?v=KIAwGfnoteE&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=31',
            'https://www.youtube.com/watch?v=c7Ap0iLwv6k&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=32',
            'https://www.youtube.com/watch?v=nLlVANBmFJM&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=33',
            'https://www.youtube.com/watch?v=NDulhXVcLuI&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=34',
            'https://www.youtube.com/watch?v=TdWhYERuv7g&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=35',
            'https://www.youtube.com/watch?v=j6c_vHdbUfg&list=PL4cUxeGkcC9jLYyp2Aoh6hcWuxFDX6PBJ&index=36'

        ]
    },

};

const VideoPageIOS2 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageIOS2;