import React from 'react';
import girlImage from '../../../assets/uiux-image.jpg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=9QzpYI2988Y&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=1',
            'https://www.youtube.com/watch?v=tLPdmfGbxfQ&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=2',
            'https://www.youtube.com/watch?v=ofQfijAfgxs&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=3',
            'https://www.youtube.com/watch?v=liE6S-PBh8U&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=4',
            'https://www.youtube.com/watch?v=yQwVn-8I7Ek&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=5',
            'https://www.youtube.com/watch?v=v6Yf3PIFmb8&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=6',
            'https://www.youtube.com/watch?v=1GS_CH_bWgQ&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=7',
            'https://www.youtube.com/watch?v=YeVvL4MYNhk&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=8',
            'https://www.youtube.com/watch?v=6vLzB6RSB7o&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=9',
            'https://www.youtube.com/watch?v=WzEaOWnsUbU&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=10',
            'https://www.youtube.com/watch?v=N8XHmJhiGjI&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=11',
            'https://www.youtube.com/watch?v=PwEZs8I9ByU&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=12',
            'https://www.youtube.com/watch?v=C_cHOo2wgaI&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=13',
            'https://www.youtube.com/watch?v=6RFdUpoaDYo&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=14',
            'https://www.youtube.com/watch?v=njGOohDWaJM&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=15',
            'https://www.youtube.com/watch?v=dJOqLqti2es&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=16',
            'https://www.youtube.com/watch?v=5TmvahjSCEg&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=17',
            'https://www.youtube.com/watch?v=Mvxw0nfNmWs&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=18',
            'https://www.youtube.com/watch?v=CqPG-2PnDSw&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=19',
            'https://www.youtube.com/watch?v=u8JLhxYOq9o&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=20',
            'https://www.youtube.com/watch?v=rbl4oYR-b-A&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=21',
            'https://www.youtube.com/watch?v=vW9Qrcl_tVw&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=22',
            'https://www.youtube.com/watch?v=0M0fuiln9Lw&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=23',
            'https://www.youtube.com/watch?v=shQcjv6n1N4&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=24',
            'https://www.youtube.com/watch?v=YAid3BFfv4Q&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=25',
            'https://www.youtube.com/watch?v=EURiBsZSc7c&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=26',
            'https://www.youtube.com/watch?v=RXoIEgLIaHQ&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=27',
            'https://www.youtube.com/watch?v=OgqjE7s-XIM&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=28',
            'https://www.youtube.com/watch?v=bsxyr5vxUbA&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=29',
            'https://www.youtube.com/watch?v=pSibpb3eiv8&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=30',
            'https://www.youtube.com/watch?v=NP5tHDNnj9U&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=31',
            'https://www.youtube.com/watch?v=JUdrF3jn4s8&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=32',
            'https://www.youtube.com/watch?v=zIoOiWpV7XM&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=33',
            'https://www.youtube.com/watch?v=hqRPPxT8ZJg&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=34',
            'https://www.youtube.com/watch?v=Rl0qYFN4GHs&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=35',
            'https://www.youtube.com/watch?v=69z9OVVesTY&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=36',
            'https://www.youtube.com/watch?v=ozL-m_ZBzB0&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=37',
            'https://www.youtube.com/watch?v=x6gpbeVYrQ0&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=38',
            'https://www.youtube.com/watch?v=671WU2Q7WcQ&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=39',
            'https://www.youtube.com/watch?v=r9338aPQTTU&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=40',
            'https://www.youtube.com/watch?v=UkgDC3HnAwc&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=41',
            'https://www.youtube.com/watch?v=ZKLflqh-2T8&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=42',
            'https://www.youtube.com/watch?v=58jzHmvbZmw&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=43',
            'https://www.youtube.com/watch?v=Hq2ZUGdwsIA&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=44',
            'https://www.youtube.com/watch?v=bnddcAo7v0E&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=45',
            'https://www.youtube.com/watch?v=90xJKoHuN-I&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=46',
            'https://www.youtube.com/watch?v=jx7fz_ftZN8&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=47',
            'https://www.youtube.com/watch?v=QANO1OX6qf8&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=48',
            'https://www.youtube.com/watch?v=OJwxO43VzVA&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=49',
            'https://www.youtube.com/watch?v=3BMMBz9BU-M&list=PLspW40rZgNemcESvd7g9mz8gldTKTLy-d&index=50',
        ]
    },
    
};

const VideoPageUI2 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageUI2;