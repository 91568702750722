import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=UNH0bE4zPtY&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=1',
            'https://www.youtube.com/watch?v=n7UFxV7zHKw&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=2',
            'https://www.youtube.com/watch?v=HxssFtenD_M&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=3',
            'https://www.youtube.com/watch?v=FSo3uS1ZfiE&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=4',
            'https://www.youtube.com/watch?v=paJ5FnJT6cI&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=5',
            'https://www.youtube.com/watch?v=nbF6g95LnYg&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=6',
            'https://www.youtube.com/watch?v=tzXUOAkWD6g&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=7',
            'https://www.youtube.com/watch?v=Y2Y8dIx7EXc&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=8',
            'https://www.youtube.com/watch?v=zzgqWRovVC8&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=9',
            'https://www.youtube.com/watch?v=7SlLLH0BGxg&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=10',
            'https://www.youtube.com/watch?v=VilvtI6K6Yg&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=11',
            'https://www.youtube.com/watch?v=Fo1A36RsoCI&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=12',
            'https://www.youtube.com/watch?v=YuLANfArajs&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=13',
            'https://www.youtube.com/watch?v=Rrf0ul01Ry8&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=14',
            'https://www.youtube.com/watch?v=khNgd4bUm24&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=15',
            'https://www.youtube.com/watch?v=F730CPdnfdI&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=16',
            'https://www.youtube.com/watch?v=OoHD9L9Ix_M&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=17',
            'https://www.youtube.com/watch?v=NVUlchb8S2w&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=18',
            'https://www.youtube.com/watch?v=ag1BEpRRCrE&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=19',
            'https://www.youtube.com/watch?v=pqhNVC-G5F4&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=20',
            'https://www.youtube.com/watch?v=_HfGCrbWxMw&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=21',
            'https://www.youtube.com/watch?v=oXo7pzMbz70&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=22',
            'https://www.youtube.com/watch?v=7xMOwmIe6LA&list=PLSzsOkUDsvdu5Mm67aBYs2YPu2OM4mFzt&index=23',
        ]
    },
    
};

const VideoPageIOS7 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageIOS7;