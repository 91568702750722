import React from 'react';
import girlImage from "../../assets/uiux-image.jpg"; // Replace with your actual image path";
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'UI/UX': {
        title: 'Introduction to UI/UX',
        videoList: [
            'https://www.youtube.com/watch?v=sAvs0itMqi0&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=1',
            'https://www.youtube.com/watch?v=wD13vIg5oCY&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=2',
            'https://www.youtube.com/watch?v=U5vCa6zk97c&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=3',
            'https://www.youtube.com/watch?v=z-OBgFrRGos&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=4',
            'https://www.youtube.com/watch?v=eCGRsbCL2gA&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=5',
            'https://www.youtube.com/watch?v=PXs8kAv1-aU&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=6',
            'https://www.youtube.com/watch?v=7iO-c7OLXZE&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=7',
            'https://www.youtube.com/watch?v=oRNTVLt2PH0&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=8',
            'https://www.youtube.com/watch?v=PfY5WHJsdh8&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=9',
            'https://www.youtube.com/watch?v=PgYx83m-6sU&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=10',
            'https://www.youtube.com/watch?v=JXo8ib5Bgjc&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=11',
            'https://www.youtube.com/watch?v=JsXb8yRTxKY&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=12',
            'https://www.youtube.com/watch?v=9BW5zI8LQQk&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=13',
            'https://www.youtube.com/watch?v=22g79AYTb0w&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=14',
            'https://www.youtube.com/watch?v=7rBSjYR_2ZE&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=15',
            'https://www.youtube.com/watch?v=wL2OvT82tjM&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=16',
            'https://www.youtube.com/watch?v=Lz39VrUVAFY&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=17',
            'https://www.youtube.com/watch?v=3J3YIrtgMC4&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=18',
            'https://www.youtube.com/watch?v=fWPur6hgmE8&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=19',
            'https://www.youtube.com/watch?v=yg_TZNN7RRA&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=20',
            'https://www.youtube.com/watch?v=9NehMmFpyg8&list=PLtCBuHKmdxOd9kxsru5t_MFvDj5o5GdDl&index=21',
        ]
    },
    
};

const VideoPageIOS8 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 UI/UX Bootcamp</h1>
                    <p>Become a graphic degain with just ONE course. </p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageIOS8;