import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=IZtsYX4j-3c&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=1',
            'https://www.youtube.com/watch?v=q-FeqYEI8Qc&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=2',
            'https://www.youtube.com/watch?v=E2Gfo-asfdA&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=3',
            'https://www.youtube.com/watch?v=IAP-GzO7yoI&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=4',
            'https://www.youtube.com/watch?v=WUzmHtpXeWA&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=5',
            'https://www.youtube.com/watch?v=0R3Ak3fTQBY&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=6',
            'https://www.youtube.com/watch?v=nyYD5mIZx9c&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=7',
            'https://www.youtube.com/watch?v=xP6rDjirSPM&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=8',
            'https://www.youtube.com/watch?v=07rLv2IXHoo&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=9',
            'https://www.youtube.com/watch?v=033Jdpu55eg&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=10',
            'https://www.youtube.com/watch?v=nBW_h8f3240&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=11',
            'https://www.youtube.com/watch?v=yCvnUV7IwUc&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=12',
            'https://www.youtube.com/watch?v=Yep5_FfM21g&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=13',
            'https://www.youtube.com/watch?v=EN3pMJ1GafI&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=14',
            'https://www.youtube.com/watch?v=0HOmOTqzQXc&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=15',
            'https://www.youtube.com/watch?v=ttNm_EggH0w&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=16',
            'https://www.youtube.com/watch?v=zNN6ku9AzY0&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=17',
            'https://www.youtube.com/watch?v=WMFuhOn0TzA&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=18',
            'https://www.youtube.com/watch?v=kzSg05Hv_QU&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=19',
            'https://www.youtube.com/watch?v=YRYIHhzhMhw&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=20',
            'https://www.youtube.com/watch?v=3k0-kC2YYeE&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=21',
            'https://www.youtube.com/watch?v=F9Jdh_qbHjU&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=22',
            'https://www.youtube.com/watch?v=sbzhrrnb66w&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=23',
            'https://www.youtube.com/watch?v=-Hdk4icaESE&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=24',
            'https://www.youtube.com/watch?v=2AUorHOqhS8&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=25',
            'https://www.youtube.com/watch?v=Z9P2Po7PFWM&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=26',
            'https://www.youtube.com/watch?v=3vrs7Xb5fyo&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=27',
            'https://www.youtube.com/watch?v=T7geyLcKyTw&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=28',
            'https://www.youtube.com/watch?v=-F9DwQM_h9I&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=29',
            'https://www.youtube.com/watch?v=egEyZJCs0pc&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=30',
            'https://www.youtube.com/watch?v=qNMb3ACq4SA&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=31',
            'https://www.youtube.com/watch?v=0KNDusS3o9U&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=32',
            'https://www.youtube.com/watch?v=rTT_u1V0cr8&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=33',
            'https://www.youtube.com/watch?v=FUrjqMYYQfI&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=34',
            'https://www.youtube.com/watch?v=rZ5w3aynnrI&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=35',
            'https://www.youtube.com/watch?v=1HkFtDClrkA&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=36',
            'https://www.youtube.com/watch?v=5oJ1uZXHfjk&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=37',
            'https://www.youtube.com/watch?v=7601wMR0Ggo&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=38',
            'https://www.youtube.com/watch?v=fd1HNqSkaP8&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=39',
            'https://www.youtube.com/watch?v=NQI0nf-z9Vw&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=40',
            'https://www.youtube.com/watch?v=kayNFc7URlg&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=41',
            'https://www.youtube.com/watch?v=gJFipAYm-Ko&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=42',
            'https://www.youtube.com/watch?v=zw8nRUEyh20&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=43',
            'https://www.youtube.com/watch?v=0MM0unbCEQ8&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=44',
            'https://www.youtube.com/watch?v=f8PJu0USU1w&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=45',
            'https://www.youtube.com/watch?v=6CUblfdM6f4&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=46',
            'https://www.youtube.com/watch?v=e8Qu1EbkSUM&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=47',
            'https://www.youtube.com/watch?v=kO49eExCqJ0&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=48',
            'https://www.youtube.com/watch?v=SuNtJSr7xkg&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=49',
            'https://www.youtube.com/watch?v=72zMXuThRXo&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=50',
            'https://www.youtube.com/watch?v=nfzsTjPhl6U&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=51',
            'https://www.youtube.com/watch?v=VgtFi1ICn2Y&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=52',
            'https://www.youtube.com/watch?v=P79Ay5lGGW0&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=53',
            'https://www.youtube.com/watch?v=G6QEA38Y7mU&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=54',
            'https://www.youtube.com/watch?v=YrXCafAx4FU&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=55',
            'https://www.youtube.com/watch?v=5LKSerr_sug&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=56',
            'https://www.youtube.com/watch?v=6GSXh6wiEm0&list=PLtfcqadUe_2x9C74v8VrUX6LE66LnCMMq&index=57',
        ]
    },
};

const VideoPageMN4 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageMN4;