import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
        ]
    },
    'React.js Fundamentals': {
        title: 'Introduction to React.js Fundamentals',
        videoList: [
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
        ]
    },
    'Node.js & Express': {
        title: 'Introduction to Node.js & Express',
        videoList: [
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
            'https://www.youtube.com/shorts/5JtCsqkr6SQ',
            'https://www.youtube.com/shorts/HLRQ69brJjk',
        ]
    },
};

const VideoPageJD10 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageJD10;