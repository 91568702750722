import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=NutVIOozWXo&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=1',
            'https://www.youtube.com/watch?v=KeZKTf_RB40&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=2',
            'https://www.youtube.com/watch?v=fvCb0UQg8yM&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=3',
            'https://www.youtube.com/watch?v=LZibJp5yVAQ&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=4',
            'https://www.youtube.com/watch?v=rgVEzNVlpXc&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=5',
            'https://www.youtube.com/watch?v=ribvhTdxns0&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=6',
            'https://www.youtube.com/watch?v=_YIOfnp_zRM&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=7',
            'https://www.youtube.com/watch?v=JY5HK8LMDnA&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=8',
            'https://www.youtube.com/watch?v=Diuxi8kuM-M&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=9',
            'https://www.youtube.com/watch?v=klOOwDkefkc&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=10',
            'https://www.youtube.com/watch?v=f5Tj6EBEVE4&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=11',
            'https://www.youtube.com/watch?v=rQUbxZX5kXc&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=12',
            'https://www.youtube.com/watch?v=q6buRke6MWk&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=13',
            'https://www.youtube.com/watch?v=J-I9lByetpE&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=15',
            'https://www.youtube.com/watch?v=O4ZY1VK3YrI&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=16',
            'https://www.youtube.com/watch?v=q-aD1MbI7Wg&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=17',
            'https://www.youtube.com/watch?v=7Lou7ak9yKI&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=18',
            'https://www.youtube.com/watch?v=a-_lfcvv33c&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=19',
            'https://www.youtube.com/watch?v=YaBAD_Ckg44&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=20',
            'https://www.youtube.com/watch?v=20E4U0sjzvA&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=21',
            'https://www.youtube.com/watch?v=Pv0uDwAK8nI&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=22',
            'https://www.youtube.com/watch?v=CG1JE0YTYqk&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=23',
            'https://www.youtube.com/watch?v=Br2tXgJn4Sg&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=24',
            'https://www.youtube.com/watch?v=A3VNWSktjWw&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=25',
            'https://www.youtube.com/watch?v=vmjW_RbpXDQ&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=26',
            'https://www.youtube.com/watch?v=CJHMUz2dgjU&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=27',
            'https://www.youtube.com/watch?v=n-ex5-id9WA&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=28',
            'https://www.youtube.com/watch?v=4Gjxia5cMnM&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=29',
            'https://www.youtube.com/watch?v=u02F6d0VQLU&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=30',
            'https://www.youtube.com/watch?v=SgG_I_7LmSg&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=31',
            'https://www.youtube.com/watch?v=xMjlQtSqpLE&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=32',
            'https://www.youtube.com/watch?v=NGmK-ZBXkIg&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=33',
            'https://www.youtube.com/watch?v=WytUOKzp97E&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=34',
            'https://www.youtube.com/watch?v=Y34vJkRWqoo&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=35',
            'https://www.youtube.com/watch?v=pc4Zjgi0ldw&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=36',
            'https://www.youtube.com/watch?v=ToWJ3C_jlIQ&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=37',
            'https://www.youtube.com/watch?v=gDmtMFqLBQ8&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=38',
            'https://www.youtube.com/watch?v=FMFMXIOsZHU&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=39',
            'https://www.youtube.com/watch?v=Ql1HDqxRIHg&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=40',
            'https://www.youtube.com/watch?v=dYHlfnHSnQs&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=41',
            'https://www.youtube.com/watch?v=qGiVRFxKCbo&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=42',
            'https://www.youtube.com/watch?v=dos5-kcVZe4&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=43',
            'https://www.youtube.com/watch?v=tkasT0TrHDA&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=44',
            'https://www.youtube.com/watch?v=uqbwsQIH3Wg&list=PLbxBBUJOe5QDvj-nIFIXqfsoEuChp84mL&index=45',
        ]
    },
};

const VideoPageJD2 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageJD2;