import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import AboutUsPage from '../aboutUspage/AboutUsPage';
import bgimage from '../assets/logo1.jpg';
import CoursePage from '../coursespage/CoursePage';
import Testimonial from '../testimonial/Testimonial';
import bgvideo from '../videos/main-video.mp4';
import "./Home.css";

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div>
        <header className="header">
          <nav className="nav container">
          <img src={bgimage} alt=''/>
            <NavLink to="/" className="nav__logo">
              Aminurmus Technology
            </NavLink>

            <div className={`nav__menu ${isOpen ? 'show-menu' : ''}`} id="nav-menu">
              <ul className="nav__list">
                <li className="nav__item">
                  <NavLink to="/CoursePage" className="nav__link">COURSES</NavLink>
                </li>
                <li className="nav__item">
                  <NavLink to="/Testimonial" className="nav__link">INTERNSHIP</NavLink>
                </li>
                <li className="nav__item">
                  <NavLink to="/AboutUsPage" className="nav__link">ABOUT US</NavLink>
                </li>
                <li className="nav__item">
                  <NavLink to="/ContectPage" className="nav__link">CONTACT</NavLink>
                </li>
                <li className="nav__item">
                  <NavLink to="/SignUpPage" className="nav__link nav__cta">SIGN UP</NavLink>
                </li>
              </ul>
              <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                {/* Close Icon */}
                &#10005;
              </div>
            </div>

            <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
              {/* Menu Icon */}
              &#9776;
            </div>
          </nav>
        </header>
      </div>
      <div className='content'>
        <video autoPlay loop muted  className='main-video'>
          <source src={bgvideo} type="video/mp4"/>
        </video>
        <div className='heading'>
          <h1><span>Aminurmus</span> <span>(Learn Earn Grow)</span></h1>
          <h2><span>Welcome</span> <span>to</span> <span>Aminurmus</span> <span>Technology</span></h2>
        </div>
        {/* <div className='btn'>
          <span><button className='btn-style'>LOGIN</button></span>
          <span><button className='btn-style'>EXPLORE</button></span>
        </div> */}
      </div>

      <CoursePage />

      <Testimonial />

      <AboutUsPage />

    </>
  )
}

export default Home;
