import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=Vi9bxu-M-ag&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD',
            'https://www.youtube.com/watch?v=aRUhd1Wd3Sw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=2',
            'https://www.youtube.com/watch?v=ofHYRdWQESo&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=3',
            'https://www.youtube.com/watch?v=0gU-qrq3gjU&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=4',
            'https://www.youtube.com/watch?v=KdWPGqT5GwE&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=5',
            'https://www.youtube.com/watch?v=e1X3WPoETsk&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=6',
            'https://www.youtube.com/watch?v=VjCHupej12U&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=7',
            'https://www.youtube.com/watch?v=dYrwawDa92U&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=8',
            'https://www.youtube.com/watch?v=GmHC1oaK9Ts&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=9',
            'https://www.youtube.com/watch?v=uNcMKFkAKuw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=10',
            'https://www.youtube.com/watch?v=fb4rgYbi84c&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=11',
            'https://www.youtube.com/watch?v=0ZGAKHoVFs0&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=12',
            'https://www.youtube.com/watch?v=tU4xz1r_aE8&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=13',
            'https://www.youtube.com/watch?v=XLLluxLg_Xc&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=14',
            'https://www.youtube.com/watch?v=xN8DPjcybpM&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=15',
            'https://www.youtube.com/watch?v=YjWktudqGN4&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=16',
            'https://www.youtube.com/watch?v=9BlNJFIMUrY&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=17',
            'https://www.youtube.com/watch?v=APhPNDikwRE&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=18',
            'https://www.youtube.com/watch?v=eMrjjsnQUDw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=19',
            'https://www.youtube.com/watch?v=tN12g5QUIqg&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=20',
            'https://www.youtube.com/watch?v=tcgXPI6Nxsw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=21',
            'https://www.youtube.com/watch?v=D0vA_R8lVLw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=22',
            'https://www.youtube.com/watch?v=n8cTHW_VxBw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=23',
            'https://www.youtube.com/watch?v=1Yfn7lH_8uA&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=24',
            'https://www.youtube.com/watch?v=remZuJHeI7s&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=25',
            'https://www.youtube.com/watch?v=ILxYkSVU9-k&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=26',
            'https://www.youtube.com/watch?v=koEnfJfRr6g&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=27',
            'https://www.youtube.com/watch?v=byCsxHUuOrk&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=28',
            'https://www.youtube.com/watch?v=mGw_BvyovgE&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=29',
            'https://www.youtube.com/watch?v=Bq58-o8mji0&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=30',
            'https://www.youtube.com/watch?v=VETRVLw7vz8&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=31',
            'https://www.youtube.com/watch?v=DrWjRwC1kYw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=32',
            'https://www.youtube.com/watch?v=wn-dseKsdQw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=33',
            'https://www.youtube.com/watch?v=8TUMa9PPdP0&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=34',
            'https://www.youtube.com/watch?v=29Mg76KoW8U&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=35',
            'https://www.youtube.com/watch?v=qvbtM03bx70&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=36',
            'https://www.youtube.com/watch?v=BPftiMM8mgI&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=37',
            'https://www.youtube.com/watch?v=_zoJ34wPIJA&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=38',
            'https://www.youtube.com/watch?v=1dFqthtouqU&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=39',
            'https://www.youtube.com/watch?v=u3v2H5mwixY&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=40',
            'https://www.youtube.com/watch?v=7k6oEConqLA&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=41',
            'https://www.youtube.com/watch?v=UXxwO_U_gXI&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=42',
            'https://www.youtube.com/watch?v=nqC-UlGTssg&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=43',
            'https://www.youtube.com/watch?v=XK8loB2jYDE&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=44',
            'https://www.youtube.com/watch?v=eK4gqHb7P7w&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=45',
            'https://www.youtube.com/watch?v=7NA3hX3IfVg&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=46',
            'https://www.youtube.com/watch?v=SslpdF_HlFc&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=47',
            'https://www.youtube.com/watch?v=cmgpJxt1q6k&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=48',
            'https://www.youtube.com/watch?v=9KBadAcKVrU&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=49',
            'https://www.youtube.com/watch?v=u2xLcx3sC_k&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=50',
            'https://www.youtube.com/watch?v=uoII7VSDF3k&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=51',
            'https://www.youtube.com/watch?v=5Udw0F6DIhA&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=52',
            'https://www.youtube.com/watch?v=x2gl4KwUIV8&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=53',
            'https://www.youtube.com/watch?v=kqFauVbe-1M&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=54',
            'https://www.youtube.com/watch?v=FqaCRrxiS_A&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=55',
            'https://www.youtube.com/watch?v=Bgf2bXr6psI&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=56',
            'https://www.youtube.com/watch?v=EL3PKEHggrE&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=57',
            'https://www.youtube.com/watch?v=ThJOl1gqIjs&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=58',
            'https://www.youtube.com/watch?v=5dDkw_yCSgw&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=59',
            'https://www.youtube.com/watch?v=kRwmnQDiRWk&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=60',
            'https://www.youtube.com/watch?v=HKX__TQ9ff0&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=61',
            'https://www.youtube.com/watch?v=Aib88vl6gDA&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=62',
            'https://www.youtube.com/watch?v=6a8CNTk9yo4&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=63',
            'https://www.youtube.com/watch?v=BBgSB2bLy60&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=64',
            'https://www.youtube.com/watch?v=Z4hN0WZbASk&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=65',
            'https://www.youtube.com/watch?v=OjF-OwLOKYE&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=66',
            'https://www.youtube.com/watch?v=xWYjtDbfkXA&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=67',
            'https://www.youtube.com/watch?v=_NZ5kb68IpA&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=68',
            'https://www.youtube.com/watch?v=Z7VSgQ_y0dg&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=69',
            'https://www.youtube.com/watch?v=VeWdk4D_xYs&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=70',
            'https://www.youtube.com/watch?v=wB3Jf7yyvfU&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=71',
            'https://www.youtube.com/watch?v=DnRY5yG67u8&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=72',
            'https://www.youtube.com/watch?v=gtHKKZJ9W-Q&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=73',
            'https://www.youtube.com/watch?v=MXs2qqWcGHo&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=74',
            'https://www.youtube.com/watch?v=pZjm9rX5GdU&list=PLDzeHZWIZsTo0wSBcg4-NMIbC0L8evLrD&index=75',
            
        ]
    },
};

const VideoPageMN3 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageMN3;