import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=PlbupGCBV6w&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=1',
            'https://www.youtube.com/watch?v=POPLF-Qc0OU&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=2',
            'https://www.youtube.com/watch?v=BxZKLKfjaRg&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=3',
            'https://www.youtube.com/watch?v=TOkU5HxES1o&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=4',
            'https://www.youtube.com/watch?v=Wsy7nhFnfbM&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=5',
            'https://www.youtube.com/watch?v=nCwQY8inRvU&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=6',
            'https://www.youtube.com/watch?v=4GXpB9EMLWM&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=7',
            'https://www.youtube.com/watch?v=4MsfBokJiSs&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=8',
            'https://www.youtube.com/watch?v=wFiVtqe1osM&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=9',
            'https://www.youtube.com/watch?v=otYwi_wW9Hk&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=10',
            'https://www.youtube.com/watch?v=gG0ynX_Sfx4&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=11',
            'https://www.youtube.com/watch?v=uuRJ1aCj8Ro&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=12',
            'https://www.youtube.com/watch?v=o_iO9WuoWaM&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=13',
            'https://www.youtube.com/watch?v=VBCOx4CqBz0&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=14',
            'https://www.youtube.com/watch?v=SJPsOFfvEQA&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=15',
            'https://www.youtube.com/watch?v=K4Kh5gw4PRE&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=16',
            'https://www.youtube.com/watch?v=h84MlHv6g4Q&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=17',
            'https://www.youtube.com/watch?v=jcwTB9fBzP0&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=18',
            'https://www.youtube.com/watch?v=EBHOo-6xn4A&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=19',
            'https://www.youtube.com/watch?v=S1dWe3f2zm0&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=20',
            'https://www.youtube.com/watch?v=weEDSjNYzv4&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=21',
            'https://www.youtube.com/watch?v=-PVcUUj_ke4&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=22',
            'https://www.youtube.com/watch?v=jwOW-qlJtxg&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=23',
            'https://www.youtube.com/watch?v=pCU7vicLCgA&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=24',
            'https://www.youtube.com/watch?v=37ly8tCGFWA&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=25',
            'https://www.youtube.com/watch?v=nnAU1dLY7gY&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=26',
            'https://www.youtube.com/watch?v=tJOJPealurs&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=27',
            'https://www.youtube.com/watch?v=4r72b1ScPLY&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=28',
            'https://www.youtube.com/watch?v=kE93zNJBCBw&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=29',
            'https://www.youtube.com/watch?v=YiqqAAvb16Q&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=30',
            'https://www.youtube.com/watch?v=tV7ZqcB9J3Y&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=31',
            'https://www.youtube.com/watch?v=TScHu-37Cqw&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=32',
            'https://www.youtube.com/watch?v=eADCg1R5_sk&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=33',
            'https://www.youtube.com/watch?v=Yv1h-QKt_KQ&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=34',
            'https://www.youtube.com/watch?v=c69Y2GpF6-U&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=35',
            'https://www.youtube.com/watch?v=giNjEgYTd9E&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=36',
            'https://www.youtube.com/watch?v=hP0Rl5euD_o&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=37',
            'https://www.youtube.com/watch?v=oQ7Le6SuLNA&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=38',
            'https://www.youtube.com/watch?v=YiIKUhtqeRM&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=39',
            'https://www.youtube.com/watch?v=flveJ5_-iFA&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=40',
            'https://www.youtube.com/watch?v=8NZZSgtTelc&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=41',
            'https://www.youtube.com/watch?v=rDjSD1vcsRI&list=PLsyeobzWxl7rrvgG7MLNIMSTzVCDZZcT4&index=42',
        ]
    },
};

const VideoPageJS3 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageJS3;