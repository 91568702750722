import React from 'react';
import girlImage from '../../assets/girl-image.jpeg'; // Replace with your actual image path
import './VideoPage.css'; // Import the CSS for styling

// Define the video data
const videos = {
    'Web Development': {
        title: 'Introduction to Web Development',
        videoList: [
            'https://www.youtube.com/watch?v=xwga58I1S94&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=1',
            'https://www.youtube.com/watch?v=npwSm4gGqw4&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=2',
            'https://www.youtube.com/watch?v=67-p24Wr4WY&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=3',
            'https://www.youtube.com/watch?v=AB1e9_7TwF4&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=4',
            'https://www.youtube.com/watch?v=FuzS1JGebyA&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=5',
            'https://www.youtube.com/watch?v=16QWkAo1o3A&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=6',
            'https://www.youtube.com/watch?v=QJVYXDqnKao&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=7',
            'https://www.youtube.com/watch?v=u6aI0BP8gew&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=8',
            'https://www.youtube.com/watch?v=YlNcnDkQXOc&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=9',
            'https://www.youtube.com/watch?v=MPrYYUkkB-8&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=10',
            'https://www.youtube.com/watch?v=yS0gE5ziwsE&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=11',
            'https://www.youtube.com/watch?v=Ab2WawLDIqc&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=12',
            'https://www.youtube.com/watch?v=Q5M2H94Csco&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=13',
            'https://www.youtube.com/watch?v=N3H60u4f9VI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=14',
            'https://www.youtube.com/watch?v=gnkeUAKi2lM&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=15',
            'https://www.youtube.com/watch?v=cGE5VCNab7k&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=16',
            'https://www.youtube.com/watch?v=dG39Y5pZR4Y&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=17',
            'https://www.youtube.com/watch?v=iCEgx8W1uUQ&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=18',
            'https://www.youtube.com/watch?v=76RAFG5aaJA&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=19',
            'https://www.youtube.com/watch?v=j9Sm9r74gQ8&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=20',
            'https://www.youtube.com/watch?v=ApASlL_yhXI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=21',
            'https://www.youtube.com/watch?v=Lp785iHyax4&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=22',
            'https://www.youtube.com/watch?v=97CGA0yJduw&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=23',
            'https://www.youtube.com/watch?v=sPw-B7JzjDY&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=24',
            'https://www.youtube.com/watch?v=WRKMJc-wFww&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=25',
            'https://www.youtube.com/watch?v=8xFr72STY9E&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=26',
            'https://www.youtube.com/watch?v=ev8IH25IdZc&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=27',
            'https://www.youtube.com/watch?v=quG-pmiSZZI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=28',
            'https://www.youtube.com/watch?v=JRYgJFd-m10&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=29',
            'https://www.youtube.com/watch?v=CPUY17YPFNU&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=30',
            'https://www.youtube.com/watch?v=jn3T4QwhdLs&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=31',
            'https://www.youtube.com/watch?v=KkerX4zG_Xw&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=32',
            'https://www.youtube.com/watch?v=IkSBVj9zySg&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=33',
            'https://www.youtube.com/watch?v=7VyJzpHqEWU&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=34',
            'https://www.youtube.com/watch?v=v1_XlrbsJKY&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=35',
            'https://www.youtube.com/watch?v=5JbGCPyWZ2s&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=36',
            'https://www.youtube.com/watch?v=o34HJJtPnAs&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=37',
            'https://www.youtube.com/watch?v=lgBXGJSHaQk&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=38',
            'https://www.youtube.com/watch?v=kDd6_-6HEsA&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=39',
            'https://www.youtube.com/watch?v=k-BnQA86JUk&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=40',
            'https://www.youtube.com/watch?v=TvojdbIIKvU&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=41',
            'https://www.youtube.com/watch?v=shr3Y7ndDu8&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=42',
            'https://www.youtube.com/watch?v=cvjiagGLjSM&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=43',
            'https://www.youtube.com/watch?v=v2_KBe1pD_w&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=44',
            'https://www.youtube.com/watch?v=F7kQlAjnjIE&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=45',
            'https://www.youtube.com/watch?v=fWA_Mr1JwO0&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=46',
            'https://www.youtube.com/watch?v=RlHz1V6RIIM&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=47',
            'https://www.youtube.com/watch?v=1j1uPaMtq1w&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=48',
            'https://www.youtube.com/watch?v=Wo0469uCSXA&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=49',
            'https://www.youtube.com/watch?v=dgwCNcbWvbQ&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=50',
            'https://www.youtube.com/watch?v=VqhsVoMLmPA&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=51',
            'https://www.youtube.com/watch?v=kiLWaEiObgw&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=52',
            'https://www.youtube.com/watch?v=itgBgmIelUo&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=53',
            'https://www.youtube.com/watch?v=0lFisrJ6Jo4&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=54',
            'https://www.youtube.com/watch?v=Li8Nklrx6ZU&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=55',
            'https://www.youtube.com/watch?v=v_GyyygdfFk&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=56',
            'https://www.youtube.com/watch?v=C8lyPu3oy3g&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=57',
            'https://www.youtube.com/watch?v=4IZiNq-s2gE&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=58',
            'https://www.youtube.com/watch?v=HB2d06wIrOE&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=59',
            'https://www.youtube.com/watch?v=_lEy4Ra6f2I&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=60',
            'https://www.youtube.com/watch?v=LPmQf-xGLfg&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=61',
            'https://www.youtube.com/watch?v=Fp5GdjRAbVI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=62',
            'https://www.youtube.com/watch?v=TneoCHCRmmc&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=63',
            'https://www.youtube.com/watch?v=D0ytcoe7Euo&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=64',
            'https://www.youtube.com/watch?v=q7nKVbxW5cY&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=65',
            'https://www.youtube.com/watch?v=xCW9wTxRsAQ&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=66',
            'https://www.youtube.com/watch?v=YPNUoVaNz-c&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=67',
            'https://www.youtube.com/watch?v=-G8qZn_0KF0&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=68',
            'https://www.youtube.com/watch?v=oWRFyUxRI44&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=69',
            'https://www.youtube.com/watch?v=ZoQ3qzz_UUs&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=70',
            'https://www.youtube.com/watch?v=VBHOV1ffVaQ&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=71',
            'https://www.youtube.com/watch?v=1QQISCPk9Ao&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=72',
            'https://www.youtube.com/watch?v=-Tq4QRlIdlA&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=73',
            'https://www.youtube.com/watch?v=GLNI3wNRzq4&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=74',
            'https://www.youtube.com/watch?v=BdjsHieikyc&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=75',
            'https://www.youtube.com/watch?v=nxFdyonhe2E&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=76',
            'https://www.youtube.com/watch?v=FCcMk6Lmq00&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=77',
            'https://www.youtube.com/watch?v=AdF5vWEyZcY&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=78',
            'https://www.youtube.com/watch?v=a2R5slNrnOM&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=79',
            'https://www.youtube.com/watch?v=v5NWf-q4hpk&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=80',
            'https://www.youtube.com/watch?v=g-48SZwnX30&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=81',
            'https://www.youtube.com/watch?v=vHRqqSn-W4Y&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=82',
            'https://www.youtube.com/watch?v=PRSsbZL7YPU&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=83',
            'https://www.youtube.com/watch?v=3gSk0Yi8LXQ&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=84',
            'https://www.youtube.com/watch?v=duQicwfbBLk&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=85',
            'https://www.youtube.com/watch?v=r3637dsssp8&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=86',
            'https://www.youtube.com/watch?v=_Quf0jBbNww&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=87',
            'https://www.youtube.com/watch?v=M0ga_Iw5NgU&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=88',
            'https://www.youtube.com/watch?v=C3Fh3_MCCco&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=89',
            'https://www.youtube.com/watch?v=wPZqVdb1dlU&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=90',
            'https://www.youtube.com/watch?v=F4jdtv0S0ZI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=91',
            'https://www.youtube.com/watch?v=tYZl-qrOAWk&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=92',
            'https://www.youtube.com/watch?v=xohm86slhmw&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=93',
            'https://www.youtube.com/watch?v=mhfc-rc5ppY&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=94',
            'https://www.youtube.com/watch?v=wPWtEtI5Ou0&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=95',
            'https://www.youtube.com/watch?v=LCgUrANJbQk&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=96',
            'https://www.youtube.com/watch?v=LEsvDyqPzPM&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=97',
            'https://www.youtube.com/watch?v=oE4p6kaqE0I&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=98',
            'https://www.youtube.com/watch?v=rYMNig1FkVI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=99',
            'https://www.youtube.com/watch?v=zmyliVBCLyI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=100',
            'https://www.youtube.com/watch?v=Vff3REZWcPs&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=101',
            'https://www.youtube.com/watch?v=i_jQltvQ3Ao&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=102',
            'https://www.youtube.com/watch?v=nUcBVhNCCfc&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=103',
            'https://www.youtube.com/watch?v=7xdhGMSE9DI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=104',
            'https://www.youtube.com/watch?v=gVk9Fgpwh-I&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=105',
            'https://www.youtube.com/watch?v=MmpZRTiGCiI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=106',
            'https://www.youtube.com/watch?v=Ex7M8kmq8tI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=107',
            'https://www.youtube.com/watch?v=xNPlClmeI1I&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=108',
            'https://www.youtube.com/watch?v=ER0ROkrqXqc&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=109',
            'https://www.youtube.com/watch?v=CHWzM9kCFFQ&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=110',
            'https://www.youtube.com/watch?v=xirpOlFSgW8&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=111',
            'https://www.youtube.com/watch?v=KuzbpblfCek&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=112',
            'https://www.youtube.com/watch?v=RjkR26RhCpY&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=113',
            'https://www.youtube.com/watch?v=uKG7Fr2rrRg&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=114',
            'https://www.youtube.com/watch?v=I2YfqpplAQI&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=115',
            'https://www.youtube.com/watch?v=sIKlfb7bb-A&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=116',
            'https://www.youtube.com/watch?v=U4fQY-Frhz8&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=117',
            'https://www.youtube.com/watch?v=CRNWC-5SeP0&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=118',
            'https://www.youtube.com/watch?v=vLjbNUinstM&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=119',
            'https://www.youtube.com/watch?v=J5fE8rzJSnM&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=120',
            'https://www.youtube.com/watch?v=LAWnN42iV1A&list=PLmRclvVt5DtnqhXTJwd-oqVRwO3bLZCGV&index=121',
        ]
    },
};

const VideoPageJD3 = () => {
    return (
        <div className="video-container">
            {/* Left Side */}
            <div className="left-side">
                <div className="course-info">
                    <h4 onClick={() => alert('Development Courses')}>Development Courses</h4>
                </div>

                <div className="course-info">
                    <h4 onClick={() => alert('Web Development Courses')}>Web Development</h4>
                </div>

                <div className="course-info">
                    <h1>The Complete 2024 Web Development Bootcamp</h1>
                    <p>Become a Full-Stack Web Developer with just ONE course. HTML, CSS, Javascript, Node, React, PostgreSQL, Web3 and DApps</p>
                    <p><strong>Bestseller</strong></p>
                    <p>1,341,937 students</p>
                    <p><strong>Created by</strong></p>
                    <p>Last updated 8/2024</p>
                    <p>English</p>
                    <p>English, Arabic [Auto]</p>
                </div>
            </div>

            {/* Right Side */}
            <div className="right-side">
                <img src={girlImage} alt="Girl" className="girl-image" />
            </div>

            {/* Video Section */}
            <div className="video-section">
                <h2>Video Tutorials</h2>
                {Object.keys(videos).map((category, index) => (
                    <div key={index} className="video-category">
                        <h3>{videos[category].title}</h3>
                        <ul>
                            {videos[category].videoList.map((videoUrl, idx) => (
                                <li key={idx}>
                                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch Video {idx + 1}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default VideoPageJD3;