import React from 'react';
import './ContectPage.css'; // Import your CSS file

const ContectPage = () => {
    return (
        <div className="container">
            <div className="left-section">
                <div className="content-box">
                    <img src=" lobo.png" alt="Company Logo" className="footer-logo" />
                    <h2 className="company-name">AMINURMUS TECHNOLOGY</h2>
                    <p>
                        This is a simple React layout divided into two sections. The left side contains a 100-word block of content. This layout makes the design visually appealing by applying border-radius and box-shadow to the content box, giving it a modern and smooth appearance. By ensuring a responsive design, this layout adapts well to different screen sizes, making it an ideal choice for forms or landing pages where content and forms are displayed side by side. The clean and minimalistic design focuses on user-friendly interactions.
                        This is a simple React layout divided into two sections. The left side contains a 100-word block of content. This layout makes the design visually appealing by applying border-radius and box-shadow to the content box, giving it a modern and smooth appearance. By ensuring a responsive design, this layout adapts well to different screen sizes, making it an ideal choice for forms or landing pages where content and forms are displayed side by side. The clean and minimalistic design focuses on user-friendly interactions.
                        This is a simple React layout divided into two sections. The left side contains a 100-word block of content. This layout makes the design visually appealing by applying border-radius and box-shadow to the content box, giving it a modern and smooth appearance. By ensuring a responsive design, this layout adapts well to different screen sizes, making it an ideal choice for forms or landing pages where content and forms are displayed side by side. The clean and minimalistic design focuses on user-friendly interactions.

                    </p>
                </div>
            </div>

            <div className="right-section">
                <form className="form-box">
                    <div className="right-conten">
                        <img
                            src="https://images.unsplash.com/photo-1536323760109-ca8c07450053"
                            alt="About Us"
                            className="contect-image"
                        />
                    </div>
                    <h2 className="Registation">Registation</h2>
                    
                    <input type="text" placeholder="Name" className="input-field" />
                    <input type="email" placeholder="Email" className="input-field" />
                    <input type="password" placeholder="Password" className="input-field" />
                    <input type="password" placeholder="Confirm Password" className="input-field" />
                    <a href="#" className="forget-password">Forgot Password?</a>
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default ContectPage;
